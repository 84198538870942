<template>
    <validation-provider tag="div" :rules="form.rules" v-slot="{errors, touched}" class="form__item"
                         :name="form.nameRus"
    >
        <input :type="form.type" @change="changeVal" v-model="form.value"
               :ref="form.name"
               :vid="form.name"
               :placeholder="form.placeholder" :name="form.name" :value="form.value" class="form__input" :class="errors.length && !success && touched ? 'form__input_error' : ''">
        <div class="form__error" v-if="errors.length && !success && touched">{{errors[0]}}</div>
        <div class="form__error" v-if="form.name === 'email' && unique === false">E-mail уже существует</div>
    </validation-provider>
</template>

<script>
    export default {
        name: "FormInput",
        props: ['form', 'index', 'success', 'unique'],
        methods: {
            changeVal(e) {
                this.$emit('changeVal', {
                    val: e.target.value,
                    index: this.index
                })
            }
        }
    }
</script>

<style scoped lang="scss">

</style>