import App from './App.vue'
import router from './router'
import store from './store'

import Vue from './plugins/vue'
import middlewarePipeline from "./plugins/middlewarePipeline";

import 'roboto-fontface'
import './assets/styles/styles.scss'
import authByToken from "./middleware/authByToken";
import authByRememberToken from "./middleware/authByRememberToken";
import getCsrf from "./middleware/getCsrf";

import axios from "./plugins/axios";
import _ from 'lodash'

import VueScrollTo from 'vue-scrollto'

import VueYandexMetrika from 'vue-yandex-metrika'




router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  let middleware = to.meta.middleware
  const context = {
    to,
    from,
    next,
    store
  }
  middleware.unshift(authByRememberToken)
  middleware.unshift(authByToken)
  middleware.unshift(getCsrf)
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})


axios.get('/setting/client')
    .then(resp => {
      const metrika = _.find(resp.data, item => item.name === 'metrika')
      if (metrika && metrika.value) {
        Vue.use(VueYandexMetrika, {
          id: metrika.value,
          router: router,
          env: 'production',
          options: {clickmap:true, trackLinks:true, accurateTrackBounce:true}
          // other options
        })
      }
    })
    .catch(e => console.log(e))



Vue.use(VueScrollTo)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

