<template>
  <div id="app">
    <div class="page">
      <div class="page__content">
        <top-all v-if="getRouteName !== 'Home'"></top-all>
        <menu-fixed></menu-fixed>
        <router-view/>
      </div>
      <div class="page__footer">
        <main-footer></main-footer>
        <notifications position="bottom right"/>
        <good-register></good-register>
        <fail-login></fail-login>
        <user-modal></user-modal>
        <callback-form></callback-form>
      </div>
    </div>
  </div>
</template>
<script>
import MainFooter from "./components/Layout/MainFooter";
import GoodRegister from "./components/Modals/GoodRegister";
import FailLogin from "./components/Modals/FailLogin";
import UserModal from "./components/Modals/UserModal";
import CallbackForm from "./components/Modals/CallbackForm";
import MenuFixed from "./components/Layout/MenuFixed";
import TopAll from "./components/Layout/TopAll";
export default {
  name: 'App',
  components: {TopAll, MenuFixed, CallbackForm, UserModal, FailLogin, GoodRegister, MainFooter},
  mounted() {
  },
  computed: {
    getUser() {
      return this.$store.getters.isAuth
    },
    getJivo() {
      return this.$store.getters.getSettingByValue('jivo')
    },
    getRouteName() {
      return this.$route.name
    }
  },
  watch: {
    getUser() {
      if (this.$store.getters.getUser) {
        this.sockets.subscribe('CHANGE_USER' , data => {
          if (data.status === 'fail' && data.id === this.$store.getters.getUser.id) {
            this.$modal.show('fail-login', {text: 'Вам отказано в участии в мероприятии'})
            this.$cookie.delete('token')
            this.$store.commit('changeUser')
            this.$cookie.delete('rememberToken')
          }
        })
      }
    },
    getJivo() {
      if (this.getJivo) {
        let jivo = document.createElement('script')
        jivo.setAttribute('src', ' //code-sb1.jivosite.com/widget/' + this.getJivo)
        jivo.setAttribute('async', true)
        document.head.appendChild(jivo)
      }
    }
  },
  sockets: {
    connect() {
      console.log('connect')
    }
  }
}
</script>

<style lang="scss" scoped>
  #app {
    min-height: 100vh;
  }
  .page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    &__content {
      flex: 1 0 auto;
    }
    &__footer {
      flex: 0 0 auto;
    }
  }
</style>
