import axios from "../plugins/axios"
import Cookie from 'js-cookie'

export default async ({next, store}) => {
    try {
        if (store.getters.getUser) {
            return next()
        }
        if (Cookie.get('token')) {
            axios.defaults.headers.common = {'Authorization': `Bearer ${Cookie.get('token')}`}
            const resp = await axios.get('auth/user')
            await store.commit('changeUser', resp.data)
        }
        return next()
    }
    catch (e) {
        if (e.response.status === 401) {
            Cookie.remove('token')
            axios.defaults.headers.common = {'Authorization': false}
        }
        return next()
    }
}