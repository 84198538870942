<template>
  <div class="partners" id="section-partners">
    <partner-section v-for="(val, index, key ) in getPartnersToData" :key="key" :partners="val"
                     :title="index"></partner-section>
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <div class="partners__button">
            <router-link :to="{name: 'Material'}" class="button button_blue">
              Материалы партнеров
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import PartnerSection from "./PartnerSection";

export default {
  name: "Partners",
  components: {PartnerSection},
  data() {
    return {
      partners: [
        {
          "id": 4,
          "name": "Aerodisk Blue",
          "avatar": "/images/AERODISK.png",
          "link": "https://aerodisk.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 1,
          "hideToIndex": false,
          "createdAt": "2022-04-03T12:37:09.000Z",
          "updatedAt": "2022-04-05T06:46:27.000Z"
        }, {
          "id": 11,
          "name": "Gblobal-Digital",
          "avatar": "/images/Global_CIO_1.png",
          "link": "https://globalcio.ru/",
          "group": "Информационные партнеры",
          "newPage": true,
          "order": 1,
          "hideToIndex": false,
          "createdAt": "2022-04-03T16:26:45.000Z",
          "updatedAt": "2022-04-05T06:46:59.000Z"
        }, {
          "id": 14,
          "name": "ICL Techno",
          "avatar": "/images/ICL.png",
          "link": "/partners/icl-techno",
          "group": "Стратегические партнеры",
          "newPage": true,
          "order": 1,
          "hideToIndex": false,
          "createdAt": "2022-04-04T06:24:05.000Z",
          "updatedAt": "2022-04-20T16:59:42.000Z"
        }, {
          "id": 5,
          "name": "ISPSystem",
          "avatar": "/images/ISPSYSTEM.png",
          "link": "https://www.ispsystem.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 2,
          "hideToIndex": false,
          "createdAt": "2022-04-03T12:39:05.000Z",
          "updatedAt": "2022-04-05T06:47:24.000Z"
        }, {
          "id": 13,
          "name": "ICT",
          "avatar": "/images/ISTONLINE.png",
          "link": "https://ict-online.ru/",
          "group": "Информационные партнеры",
          "newPage": true,
          "order": 2,
          "hideToIndex": false,
          "createdAt": "2022-04-03T16:27:48.000Z",
          "updatedAt": "2022-04-05T06:47:36.000Z"
        }, {
          "id": 25,
          "name": "IVA Technologies",
          "avatar": "/images/Technologies (1).png",
          "link": "/partners/iva-technologies",
          "group": "Стратегические партнеры",
          "newPage": true,
          "order": 2,
          "hideToIndex": false,
          "createdAt": "2022-04-13T13:46:24.000Z",
          "updatedAt": "2022-04-20T16:59:05.000Z"
        }, {
          "id": 6,
          "name": "Softline We Know",
          "avatar": "/images/SOFTLINE.png",
          "link": "https://softline.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 3,
          "hideToIndex": false,
          "createdAt": "2022-04-03T12:39:39.000Z",
          "updatedAt": "2022-04-05T06:47:49.000Z"
        }, {
          "id": 7,
          "name": "MIG",
          "avatar": "/images/MOBILEGROUP.png",
          "link": "https://m-infogroup.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 4,
          "hideToIndex": false,
          "createdAt": "2022-04-03T12:40:09.000Z",
          "updatedAt": "2022-04-05T06:48:06.000Z"
        }, {
          "id": 19,
          "name": "commnews",
          "avatar": "/images/comnews.png",
          "link": "https://www.comnews.ru/",
          "group": "Информационные партнеры",
          "newPage": true,
          "order": 4,
          "hideToIndex": false,
          "createdAt": "2022-04-07T09:48:48.000Z",
          "updatedAt": "2022-04-07T09:49:26.000Z"
        }, {
          "id": 40,
          "name": "Астра",
          "avatar": "/images/astra.png",
          "link": "https://astralinux.ru/",
          "group": "Стратегические партнеры",
          "newPage": true,
          "order": 4,
          "hideToIndex": true,
          "createdAt": "2022-04-20T10:47:22.000Z",
          "updatedAt": "2022-05-11T16:30:54.000Z"
        }, {
          "id": 8,
          "name": "Searchinform",
          "avatar": "/images/searchinform.png",
          "link": "https://searchinform.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 5,
          "hideToIndex": false,
          "createdAt": "2022-04-03T12:40:44.000Z",
          "updatedAt": "2022-04-05T06:48:33.000Z"
        }, {
          "id": 31,
          "name": "cisoclub",
          "avatar": "/images/cisoclub.png",
          "link": "https://cisoclub.ru/",
          "group": "Информационные партнеры",
          "newPage": true,
          "order": 5,
          "hideToIndex": false,
          "createdAt": "2022-04-15T13:06:38.000Z",
          "updatedAt": "2022-04-17T21:02:27.000Z"
        }, {
          "id": 9,
          "name": "Инфотекс",
          "avatar": "/images/infotech.png",
          "link": "https://infotecs.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 6,
          "hideToIndex": false,
          "createdAt": "2022-04-03T12:41:24.000Z",
          "updatedAt": "2022-04-05T06:48:58.000Z"
        }, {
          "id": 10,
          "name": "РТУ МИРЭА",
          "avatar": "/images/MIREA.png",
          "link": "https://www.mirea.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 7,
          "hideToIndex": false,
          "createdAt": "2022-04-03T12:42:50.000Z",
          "updatedAt": "2022-04-05T06:49:08.000Z"
        }, {
          "id": 15,
          "name": "Форс",
          "avatar": "/images/FORS.png",
          "link": "https://edu.fors.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 8,
          "hideToIndex": false,
          "createdAt": "2022-04-04T10:50:15.000Z",
          "updatedAt": "2022-04-15T07:30:06.000Z"
        }, {
          "id": 16,
          "name": "Depo",
          "avatar": "/images/DEPO.png",
          "link": "https://www.depo.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 9,
          "hideToIndex": false,
          "createdAt": "2022-04-04T10:50:43.000Z",
          "updatedAt": "2022-04-05T06:49:30.000Z"
        }, {
          "id": 17,
          "name": "КРОК",
          "avatar": "/images/krok.png",
          "link": "https://www.croc.ru",
          "group": "Партнеры",
          "newPage": true,
          "order": 10,
          "hideToIndex": false,
          "createdAt": "2022-04-07T09:17:02.000Z",
          "updatedAt": "2022-04-07T09:17:02.000Z"
        }, {
          "id": 18,
          "name": "AXOFT",
          "avatar": "/images/AXOFT.png",
          "link": "https://axoft.ru",
          "group": "Партнеры",
          "newPage": true,
          "order": 11,
          "hideToIndex": false,
          "createdAt": "2022-04-07T09:17:52.000Z",
          "updatedAt": "2022-04-07T09:17:52.000Z"
        }, {
          "id": 20,
          "name": "step",
          "avatar": "/images/logo_step.png",
          "link": "https://step.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 14,
          "hideToIndex": false,
          "createdAt": "2022-04-08T07:34:02.000Z",
          "updatedAt": "2022-04-13T11:26:43.000Z"
        }, {
          "id": 21,
          "name": "Гравитон",
          "avatar": "/images/H_GRAVITON.png",
          "link": "https://graviton.3l.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 15,
          "hideToIndex": false,
          "createdAt": "2022-04-13T13:42:30.000Z",
          "updatedAt": "2022-04-13T13:42:30.000Z"
        }, {
          "id": 22,
          "name": "Полиматика",
          "avatar": "/images/polymatica.png",
          "link": "https://www.polymatica.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 16,
          "hideToIndex": false,
          "createdAt": "2022-04-13T13:43:14.000Z",
          "updatedAt": "2022-04-13T13:43:14.000Z"
        }, {
          "id": 23,
          "name": "S-Terra",
          "avatar": "/images/S-terra.png",
          "link": "https://www.s-terra.ru",
          "group": "Партнеры",
          "newPage": true,
          "order": 17,
          "hideToIndex": false,
          "createdAt": "2022-04-13T13:43:53.000Z",
          "updatedAt": "2022-04-13T13:59:33.000Z"
        }, {
          "id": 24,
          "name": "НГТУ НЭТИ",
          "avatar": "/images/NGTU.png",
          "link": "https://www.nstu.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 18,
          "hideToIndex": false,
          "createdAt": "2022-04-13T13:44:35.000Z",
          "updatedAt": "2022-04-13T21:43:28.000Z"
        }, {
          "id": 26,
          "name": "МИЭМ НИУ ВШЭ",
          "avatar": "/images/ВШЭ.png",
          "link": "https://www.hse.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 18,
          "hideToIndex": false,
          "createdAt": "2022-04-14T04:46:36.000Z",
          "updatedAt": "2022-04-14T04:46:36.000Z"
        }, {
          "id": 27,
          "name": "Softline Education",
          "avatar": "/images/soft_line_U.png",
          "link": "https://edu.softline.com/",
          "group": "Партнеры",
          "newPage": true,
          "order": 20,
          "hideToIndex": false,
          "createdAt": "2022-04-14T09:43:33.000Z",
          "updatedAt": "2022-04-14T12:58:24.000Z"
        }, {
          "id": 28,
          "name": "Мерлион",
          "avatar": "/images/merlion.png",
          "link": "https://merlion.com/",
          "group": "Партнеры",
          "newPage": true,
          "order": 21,
          "hideToIndex": false,
          "createdAt": "2022-04-14T09:44:02.000Z",
          "updatedAt": "2022-04-14T12:43:36.000Z"
        }, {
          "id": 29,
          "name": "my_office",
          "avatar": "/images/Мойофис.png",
          "link": "https://myoffice.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 22,
          "hideToIndex": false,
          "createdAt": "2022-04-15T13:05:42.000Z",
          "updatedAt": "2022-04-19T10:16:26.000Z"
        }, {
          "id": 30,
          "name": "mgu_BMK",
          "avatar": "/images/mgu_BMK.png",
          "link": "https://cs.msu.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 23,
          "hideToIndex": false,
          "createdAt": "2022-04-15T13:06:10.000Z",
          "updatedAt": "2022-04-16T11:17:08.000Z"
        }, {
          "id": 33,
          "name": "RuBackup",
          "avatar": "/images/rubackup.png",
          "link": "https://rubackup.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 23,
          "hideToIndex": false,
          "createdAt": "2022-04-20T10:43:21.000Z",
          "updatedAt": "2022-05-11T16:30:26.000Z"
        }, {
          "id": 34,
          "name": "Rupost",
          "avatar": "/images/rupost.png",
          "link": "http://rupost.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 24,
          "hideToIndex": false,
          "createdAt": "2022-04-20T10:43:48.000Z",
          "updatedAt": "2022-05-11T16:29:12.000Z"
        }, {
          "id": 35,
          "name": "termidesk",
          "avatar": "/images/termidesk.png",
          "link": "https://termidesk.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 25,
          "hideToIndex": false,
          "createdAt": "2022-04-20T10:44:17.000Z",
          "updatedAt": "2022-05-11T16:28:12.000Z"
        }, {
          "id": 36,
          "name": "WP logo",
          "avatar": "/images/worksPad.png",
          "link": "https://www.workspad.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 26,
          "hideToIndex": false,
          "createdAt": "2022-04-20T10:44:53.000Z",
          "updatedAt": "2022-05-11T16:29:50.000Z"
        }, {
          "id": 37,
          "name": "Брест",
          "avatar": "/images/Брест.png",
          "link": "https://astralinux.ru/products/pk-brest/",
          "group": "Партнеры",
          "newPage": true,
          "order": 27,
          "hideToIndex": false,
          "createdAt": "2022-04-20T10:45:45.000Z",
          "updatedAt": "2022-05-13T14:54:19.000Z"
        }, {
          "id": 39,
          "name": "ALD Pro",
          "avatar": "/images/aldpro.png",
          "link": "https://astralinux.ru/products/ald-pro",
          "group": "Партнеры",
          "newPage": true,
          "order": 28,
          "hideToIndex": false,
          "createdAt": "2022-04-20T10:46:54.000Z",
          "updatedAt": "2022-05-13T14:57:43.000Z"
        }, {
          "id": 41,
          "name": "Иннополис",
          "avatar": "/images/шттшзщдшы_гтшмукышен.png",
          "link": "https://innopolis.university/",
          "group": "Партнеры",
          "newPage": true,
          "order": 28,
          "hideToIndex": false,
          "createdAt": "2022-04-20T10:48:05.000Z",
          "updatedAt": "2022-05-13T14:57:02.000Z"
        }, {
          "id": 42,
          "name": "ITHUB",
          "avatar": "/images/ithubacademy.png",
          "link": "https://academy.ithub.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 29,
          "hideToIndex": false,
          "createdAt": "2022-04-20T10:48:32.000Z",
          "updatedAt": "2022-05-13T14:55:25.000Z"
        }, {
          "id": 43,
          "name": "БГМУ",
          "avatar": "/images/BGMU.png",
          "link": "https://bashgmu.ru/",
          "group": "Партнеры",
          "newPage": true,
          "order": 30,
          "hideToIndex": false,
          "createdAt": "2022-04-20T10:48:53.000Z",
          "updatedAt": "2022-05-13T14:58:25.000Z"
        }]
    }
  },
  computed: {
    getPartnersToData() {
      let partners = _.filter(this.partners, partner => !partner.hideToIndex)
      partners = _.groupBy(partners, 'group')
      return {
        'Стратегические партнеры': partners['Стратегические партнеры'],
        'Партнеры': partners['Партнеры'],
        'Информационные партнеры': partners['Информационные партнеры'],
      }
    }
  }
  ,
}
</script>

<style scoped lang="scss">
.partners {
  padding-top: rem(15);
}

.partners__button {
  max-width: 280px;
  margin: rem(20) auto 0;
}
</style>
