<template>
  <div class="top" :class="getShowForm ? 'active' : ''">
    <div class="container">
      <div class="row d-flex align-items-stretch">
        <div class="col-12 col-md-8">
<!--          <div class="top__absolute" v-if="!$store.getters.isAuth">-->
<!--            <svg width="180" height="57" viewBox="0 0 180 57" fill="none"-->
<!--                 xmlns="http://www.w3.org/2000/svg">-->
<!--              <path-->
<!--                  d="M16.0125 23.883H19.0822L24.9049 44.93H22.3733L20.6961 38.9074H14.1771L12.6581 44.93H10.1265L16.0125 23.883ZM17.5315 25.5809H17.4682L14.7151 36.7931H20.1898L17.5315 25.5809Z"-->
<!--                  fill="white"/>-->
<!--              <path-->
<!--                  d="M29.0191 38.2664C29.0191 41.3738 29.8735 43.328 32.9432 43.328C35.2849 43.328 36.8989 42.3028 36.8989 39.8041C36.8989 37.0811 35.633 36.4084 33.5128 35.6075L30.4432 34.4543C28.5444 33.7175 27.0887 32.2759 27.0887 28.9122C27.0887 25.5485 29.3672 23.4662 32.7533 23.4662C36.3609 23.4662 38.5128 25.5485 38.671 29.3927H36.3925C36.3925 27.0862 35.2533 25.5485 32.9432 25.5485C30.3799 25.5485 29.4938 27.1182 29.4938 28.8802C29.4938 30.8023 30.0001 31.6672 31.3292 32.1477L34.652 33.3971C37.9432 34.6465 39.3039 36.6326 39.3039 39.4197C39.3039 43.392 36.8672 45.3782 32.8799 45.3782C28.8925 45.3782 26.709 42.7513 26.709 38.2344L29.0191 38.2664Z"-->
<!--                  fill="white"/>-->
<!--              <path d="M48.2596 44.93H45.9495V25.9973H40.5381V23.883H53.6077V25.9973H48.228L48.2596 44.93Z"-->
<!--                    fill="white"/>-->
<!--              <path-->
<!--                  d="M58.5128 44.93H56.2026V23.883H62.5318C66.171 23.883 68.5761 25.677 68.5761 29.6173C68.5761 32.0199 67.2153 34.1342 64.8102 34.4546V34.5186C66.9938 34.7749 68.3545 36.697 68.3545 39.2918C68.3545 43.6806 68.7343 43.8408 69.0507 44.4815V44.962H66.709C66.2343 43.5845 66.0444 42.3672 66.0444 40.0927C66.0444 37.3057 64.8419 35.7039 62.2469 35.7039H58.5128V44.93ZM58.5128 33.5576H62.5318C64.1457 33.5576 66.171 32.7887 66.171 29.7134C66.171 26.67 64.4621 25.9973 62.4368 25.9973H58.5128V33.5576Z"-->
<!--                  fill="white"/>-->
<!--              <path-->
<!--                  d="M77.4051 23.883H80.4747L86.2975 44.93H83.7659L82.1203 38.9074H75.5697L74.0507 44.962H71.519L77.4051 23.883ZM78.8925 25.5809H78.8292L76.076 36.7931H81.5507L78.8925 25.5809Z"-->
<!--                  fill="white"/>-->
<!--              <path d="M99.462 42.8477H107.943V44.962H97.1519V23.883H99.462V42.8477Z" fill="white"/>-->
<!--              <path d="M113.069 44.93H110.759V23.883H113.069V44.93Z" fill="white"/>-->
<!--              <path-->
<!--                  d="M121.044 23.883L126.93 41.1178C127.183 41.8546 127.405 42.4633 127.658 43.4244H127.753C127.721 42.6555 127.658 41.8546 127.658 41.0858V23.883H129.968V44.93H126.171L120.38 28.0796C120.031 27.0545 119.747 25.9973 119.588 24.9402H119.525C119.557 25.9973 119.588 27.0865 119.588 28.1437V44.93H117.278V23.883H121.044Z"-->
<!--                  fill="white"/>-->
<!--              <path-->
<!--                  d="M136.519 23.883V39.0356C136.519 40.6693 136.741 43.3283 140.443 43.3283C144.146 43.3283 144.367 40.6693 144.367 39.0356V23.883H146.677V39.8685C146.677 43.6166 144.209 45.4105 140.443 45.4105C136.677 45.4105 134.209 43.6166 134.209 39.8685V23.883H136.519Z"-->
<!--                  fill="white"/>-->
<!--              <path-->
<!--                  d="M152.342 23.883L156.709 32.084L161.044 23.883H163.608L158.07 34.1342L163.766 44.962H161.139L156.709 36.1524L152.215 44.962H149.652L155.38 34.1342L149.778 23.883H152.342Z"-->
<!--                  fill="white"/>-->
<!--              <path-->
<!--                  d="M169.747 11.2284H98.2278C97.5949 11.2284 97.057 11.741 97.057 12.4137C97.057 13.0544 97.5633 13.599 98.2278 13.599H169.747C174.114 13.599 177.69 17.187 177.69 21.6398V46.4349C177.69 50.8558 174.146 54.4757 169.747 54.4757H121.646H10.2532C5.88608 54.4757 2.31013 50.8878 2.34177 46.4349V21.6078C2.34177 17.187 5.88608 13.599 10.2532 13.567H81.8354C82.4684 13.567 83.0063 13.0544 83.0063 12.3817C83.0063 11.741 82.5 11.1964 81.8354 11.1964H10.2532C4.58861 11.2284 0 15.8735 0 21.6078V46.4029C0 52.1372 4.58861 56.7823 10.2532 56.7823H121.646H169.747C175.411 56.7823 180 52.1372 180 46.4029V21.6078C180 15.8735 175.411 11.2284 169.747 11.2284Z"-->
<!--                  fill="white"/>-->
<!--              <path-->
<!--                  d="M99.019 7.44871L92.9114 6.39155L90.6012 1.42612L90 0.176758L87.0886 6.35952L80.981 7.41667L85.3481 12.2219L84.367 19.0134L90 15.8739L95.6329 19.0134L94.6202 12.2219L98.1645 8.31365L99.019 7.44871ZM93.8607 11.9336L94.6835 17.6038L89.9683 15.009L85.2848 17.6038L86.1076 11.9336L82.4683 7.92923L87.5633 7.03225L89.9683 1.90665L92.3734 7.03225L97.4683 7.92923L93.8607 11.9336Z"-->
<!--                  fill="white"/>-->
<!--              <path-->
<!--                  d="M86.5505 12.959L88.2593 15.2975L88.9872 14.8811L86.1707 8.98664L89.8732 14.4006L89.9998 14.3365L91.2973 15.0413L88.4492 8.69833L93.291 16.1625L93.9555 16.5149L93.8606 15.8101L90.7277 8.41001L93.4176 12.7347L93.2593 11.7417L94.0821 10.8447L93.0062 8.08966L94.3986 10.4923L96.3922 8.28187L92.0251 7.54507L89.9998 3.22034L87.9745 7.54507L83.6074 8.28187L86.7403 11.7417L86.5505 12.959Z"-->
<!--                  fill="white"/>-->
<!--              <path d="M86.3925 14.2082L86.0444 16.5147L87.2153 15.874L86.3925 14.2082Z" fill="white"/>-->
<!--              <path-->
<!--                  d="M170.475 26.5418C170.475 28.0474 169.272 29.2968 167.753 29.2968C166.266 29.2968 165.032 28.0794 165.032 26.5418C165.032 25.0361 166.234 23.7867 167.753 23.7867C167.753 23.7867 167.753 23.7867 167.785 23.7867C169.241 23.8188 170.475 25.0361 170.475 26.5418ZM165.443 26.5418C165.443 27.8232 166.487 28.8803 167.753 28.8803C169.019 28.8803 170.063 27.8232 170.063 26.5418C170.063 25.2604 169.019 24.2032 167.753 24.2032C166.487 24.2032 165.443 25.2604 165.443 26.5418ZM166.741 25.0041H168.07C168.576 25.0041 169.146 25.1643 169.146 25.837C169.146 26.5097 168.671 26.6699 168.196 26.6699L169.114 28.0794H168.671L167.817 26.6699H167.152V28.0794H166.772L166.741 25.0041ZM167.12 26.3816H168.006C168.291 26.3816 168.734 26.3175 168.734 25.805C168.734 25.3565 168.323 25.2924 167.943 25.2924H167.089V26.3816H167.12Z"-->
<!--                  fill="white"/>-->
<!--            </svg>-->
<!--          </div>-->
          <menu-top></menu-top>
          <div class="top__logo">
            <router-link :to="{name: 'Home'}">
              <img src="@/assets/img/logo.png" alt="Logo">
            </router-link>
          </div>
          <div class="top__date">
            {{ getDateStart }}
          </div>
          <div class="top__title">
            {{ $store.getters.getEvent.name }}
          </div>
          <div class="top__desc" v-html="$store.getters.getEvent.description">
            <p>На мероприятии пройдёт демонстрация решений миграции на отечественные IT-продукты и практика
              цифровой трансформации для образовательных учреждений.</p>

            <p>Мы поделимся опытом обучения пользователей отечественных программных продуктов и подготовки
              российских IT-специалистов.</p>
          </div>
          <div class="top__buttons">
            <div class="top__button" v-if="!$store.getters.isAuth">
                            <span class="button"
                                  @click.prevent="$store.commit('changeRightModal', 'login')">Войти</span>
            </div>
<!--            <div class="top__button" v-if="!$store.getters.isAuth">-->
<!--              <span class="button" @click.prevent="$store.commit('changeRightModal', 'register')">Регистрация</span>-->
<!--            </div>-->
            <div class="top__button" v-if="$store.getters.isAuth" :style="$store.getters.isAuth ? 'margin-left: 0' : ''">
              <router-link :to="{name: 'Telecast'}" class="button">Смотреть видео</router-link>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 d-md-flex align-items-center justify-content-end">
          <home-slider
              v-if="$store.getters.getContentByType('slider') && $store.getters.getContentByType('slider').length"></home-slider>
          <!--                    <home-contact></home-contact>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import HomeContact from "./HomeContact";
import HomeSlider from "./HomeSlider";
import moment from 'moment-timezone'
import MenuTop from "../Layout/Menu";

export default {
  name: "HomeTop",
  components: {
    MenuTop,
    HomeSlider,
    // HomeContact
  },
  data() {
    return {
      showLogin: false,
      showRegister: true
    }
  },
  computed: {
    getShowForm() {
      return this.$store.getters.getRightModal
    },
    getDateStart() {
      if (this.$store.getters.getEvent) {
        moment.locale('ru')
        return moment(this.$store.getters.getEvent.dateStart).tz('Europe/Moscow').format('DD MMMM YYYY') + ' года'
      } else {
        return null
      }
    }
  },
}
</script>

<style scoped lang="scss">
.top {
  height: 80vh;
  background-color: #063B67;
  background-image: url(~@/assets/img/home.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @include media(768) {
    height: auto;
  }

  &::before {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    background-image: url(~@/assets/img/image-1.png);
    background-repeat: no-repeat;
    background-position: 80% top;
    background-size: 50% auto;
    mix-blend-mode: hard-light;
    filter: blur(4px);
    transition: all 1s;
  }

  &.active {
    &::before {
      background-position: 10% top;
    }
  }

  .container {
    position: relative;
    height: 100%;
  }

  &__logo {
    padding-top: rem(20);

    img {
      max-width: 110px;
      min-width: 80px;
      width: 30vw;
      vertical-align: top;
      @include media(1919) {
        max-width: 110px;
      }
      @include media(1439) {
        max-width: 110px;
      }
      @include media(1280) {
        max-width: 90px;
      }
      @include media(1200) {
        max-width: 70px;
      }
      @include media(1110) {
        max-width: 80px;
      }
      @include media(1023) {
        max-width: 95px;
      }
      @include media(480) {
        max-width: 70px;
      }
    }
  }

  &__absolute {
    position: absolute;
    top: rem(20);
    right: 0;
    @include media(768) {
      right: rem(10);
    }

    svg {
      @include media(1919) {
        max-width: 180px;
        height: auto;
      }
      @include media(1439) {
        max-width: 130px;
      }
      @include media(1023) {
        max-width: 95px;
      }

    }
  }

  &__date {
    font-size: rem(21);
    //text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    padding-top: rem(32);
    //display: none;
    @include media(1919) {
      font-size: rem(21);
    }
    @include media(1536) {
      display: none;
    }
    @include media(1439) {
      display: block;
      font-size: rem(21);
      font-weight: normal;
      padding-top: rem(10);
    }
    @include media(1280) {
      padding-top: rem(2);
      font-size: rem(16);
    }
    @include media(768) {
      font-size: rem(18);
      padding-top: rem(10);
    }
  }

  &__title {
    padding-top: rem(5);
    color: #fff;
    max-width: 450px;
    font-weight: bold;
    font-size: rem(42);
    line-height: 1.3;
    //@include media(1919) {
    //  font-size: rem(32);
    //}
    @include media(1536) {
      max-width: 560px;
      font-size: rem(28);
    }
    @include media(1439) {
      font-size: rem(32);
    }
    @include media(1280) {
      //padding-top: rem(10);
      font-size: rem(24);
      //font-size: rem(18);
    }
    @include media(992) {
      font-size: rem(32);
    }
    @include media(768) {
      font-size: rem(32);
      //padding-top: rem(10);
    }
  }

  &__desc {
    padding-top: rem(5);
    color: #fff;
    font-size: rem(18);
    font-weight: 400;
    line-height: 1.5;
    max-width: 715px;

    &::v-deep {
      p {
        font-weight: 500;

        &:last-child {
          max-width: 320px;
        }
      }

      .ql-size-small {
        font-size: rem(10);
        line-height: 1.1;
        @include media(768) {
          font-size: rem(12);
        }
      }

      strong {
        text-transform: uppercase;
        font-weight: 600;
      }
    }

    @include media(1919) {
      font-size: rem(18);
    }
    @include media(1523) {
      &::v-deep {
        p {
          margin-bottom: rem(4);
        }
      }
    }
    @include media(1400) {
      font-size: rem(16);
      &::v-deep {
        p {
          margin-bottom: rem(6);
        }
      }
    }
    @include media(1280) {
      //padding-top: rem(10);
      font-size: rem(14);

    }
    @include media(992) {
      font-size: rem(16);
    }

    &::v-deep p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__buttons {
    padding-top: rem(10);
    display: flex;
    @include media(1919) {
      padding-top: rem(0);
    }
    @include media(1400) {
      padding-top: 0;
    }
    @include media(768) {
      flex-direction: column;
      padding-bottom: rem(30);
    }
  }

  &__button {
    max-width: 298px;
    width: 100%;
    padding-top: rem(20);

    &:last-child {
      margin-left: rem(20);
    }

    @include media(1280) {
      padding-top: rem(15);
      .button {
        height: 38px;
      }
    }
    @include media(992) {
      .button {
        height: 48px;
      }
    }
    @include media(768) {
      max-width: 100%;
      padding-top: rem(10);
      &:last-child {
        margin-left: 0;
      }
    }
  }

}
</style>
