<template>
  <modal name="good-register" :adaptive="true" :height="'auto'" :maxWidth="341">
    <div class="popup__wrapper">
      <div class="popup__title">Спасибо!<br/>
        Вы успешно подали заявку
      </div>
      <div class="popup__desc">
        <p>Ожидайте одобрения организаторов мероприятия. В ближайшее время Вы получите подробную информацию на email,
          который указали при регистрации.</p>
        <p>Внимание!
          Заявки на офлайн-участие рассматриваются до 3 дней в связи с большим количеством запросов. Организаторы оставляют за собой право изменить формат присутствия.</p>

      </div>
      <div class="popup__button">
        <button class="button button_blue" @click="$modal.hide('good-register')">ок</button>
      </div>
    </div>

  </modal>
</template>

<script>
export default {
  name: "GoodRegister"
}
</script>

<style scoped>

</style>