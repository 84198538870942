<template>
  <div class="program__text" v-if="program.content.length || program.speaker.length">
    <div class="container">
      <div class="row">
        <div class="col-12" v-if="!getSpeakersCount">
          <div class="program__info" v-html="program.content"></div>
        </div>
        <div class="col-12" v-else>
          <div class="row">
            <div class="col-12">
              <div class="row ">
                <div :class="['col-' + getSpeakerByRow, program.speaker.length && index !== program.speaker.length - 1 ? 'pb-2' : '']" class="col-lg-12" v-for="(speaker, index) in program.speaker" :key="speaker.id">
                  <div class="program__speaker d-flex align-items-center flex-column flex-lg-row" @click="showSpeaker(speaker.id)">
                    <div class="program__avatar">
                      <img :src="speaker.avatar" :alt="speaker.name">
                    </div>
                    <div class="program__cont">
                      <div class="program__name">{{speaker.name}}</div>
                      <div class="program__job">{{speaker.job}}</div>
                      <div class="program__link pt-2">
                        <a href="#" @click.prevent="showSpeaker(speaker.id)">Подробнее</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12" :class="'col-lg-' + (12 - getSpeakersRows)">
              <div class="program__info" v-html="program.content"></div>
            </div>
          </div>
          <modal :name="'speaker_' + program.id"  :adaptive="true" :height="'auto'" :maxWidth="400" :maxHeight="500" :scrollable="true" style="overflow-y: auto">
            <div class="popup__wrapper" v-if="activeSpeaker">
              <div class="row">
                <div class="col-4">
                  <div class="program__avatar program__avatar_small">
                    <img :src="activeSpeaker.avatar" :alt="activeSpeaker.name">
                  </div>
                </div>
                <div class="col-8">
                  <div class="program__title">
                    Тема выступления:
                  </div>
                  <div class="program__theme"  v-html="program.name"></div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="speaker__name">{{activeSpeaker.name}}</div>
                  <div class="speaker__job">{{activeSpeaker.job}}</div>
                  <div class="speaker__line"></div>
                  <div class="speaker__text" v-if="activeSpeaker.info" v-html="activeSpeaker.info"></div>
                  <div class="row">
                    <div class="col-6">
                      <div class="speaker__replace speaker__replace_prev d-flex align-items-center justify-content-between" @click="showSpeaker(prevSpeaker.id)" v-if="prevSpeaker">
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g filter="url(#filter0_d_485_1916)">
                            <circle cx="22" cy="21" r="21" transform="rotate(-90 22 21)" fill="#59B980"/>
                          </g>
                          <path d="M18.2601 31.4879L18.485 31.5383L18.6694 31.4001L28.209 24.2482L28.2097 24.2476C29.6694 23.1489 30.5 21.8687 30.5 20.5C30.5 19.1313 29.6694 17.8511 28.2097 16.7524L28.209 16.7518L18.6694 9.59994L18.485 9.46169L18.2601 9.51211L17.8906 9.59495L16.8322 9.83225L17.7001 10.4829L27.2393 17.6345C28.5336 18.6066 29.0801 19.5975 29.0801 20.5C29.0801 21.4025 28.5336 22.3933 27.2394 23.3654C27.2394 23.3654 27.2393 23.3654 27.2393 23.3655L17.7001 30.5171L16.8322 31.1677L17.8906 31.405L18.2601 31.4879Z" fill="white" stroke="white"/>
                          <defs>
                            <filter id="filter0_d_485_1916" x="0" y="0" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                              <feOffset dy="1"/>
                              <feGaussianBlur stdDeviation="0.5"/>
                              <feComposite in2="hardAlpha" operator="out"/>
                              <feColorMatrix type="matrix" values="0 0 0 0 0.34902 0 0 0 0 0.72549 0 0 0 0 0.501961 0 0 0 0.77 0"/>
                              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_485_1916"/>
                              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_485_1916" result="shape"/>
                            </filter>
                          </defs>
                        </svg>
                        <span>Предыдущий</span>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="speaker__replace speaker__replace_next d-flex align-items-center justify-content-between" @click="showSpeaker(nextSpeaker.id)" v-if="nextSpeaker">
                        <span>Следующий</span>
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g filter="url(#filter0_d_485_1916)">
                            <circle cx="22" cy="21" r="21" transform="rotate(-90 22 21)" fill="#59B980"/>
                          </g>
                          <path d="M18.2601 31.4879L18.485 31.5383L18.6694 31.4001L28.209 24.2482L28.2097 24.2476C29.6694 23.1489 30.5 21.8687 30.5 20.5C30.5 19.1313 29.6694 17.8511 28.2097 16.7524L28.209 16.7518L18.6694 9.59994L18.485 9.46169L18.2601 9.51211L17.8906 9.59495L16.8322 9.83225L17.7001 10.4829L27.2393 17.6345C28.5336 18.6066 29.0801 19.5975 29.0801 20.5C29.0801 21.4025 28.5336 22.3933 27.2394 23.3654C27.2394 23.3654 27.2393 23.3654 27.2393 23.3655L17.7001 30.5171L16.8322 31.1677L17.8906 31.405L18.2601 31.4879Z" fill="white" stroke="white"/>
                          <defs>
                            <filter id="filter0_d_485_1916" x="0" y="0" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                              <feOffset dy="1"/>
                              <feGaussianBlur stdDeviation="0.5"/>
                              <feComposite in2="hardAlpha" operator="out"/>
                              <feColorMatrix type="matrix" values="0 0 0 0 0.34902 0 0 0 0 0.72549 0 0 0 0 0.501961 0 0 0 0.77 0"/>
                              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_485_1916"/>
                              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_485_1916" result="shape"/>
                            </filter>
                          </defs>
                        </svg>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "ProgramContent",
  props: ['program'],
  data() {
    return {
      activeSpeaker: null,
      prevSpeaker: null,
      nextSpeaker: null
    }
  },
  computed: {
    getSpeakersCount() {
      return this.program.speaker.length
    },
    getSpeakersRows() {
      const speakers = this.program.speaker.length
      return speakers * 2 >= 6 ? 6 : speakers * 2
    },
    getSpeakerByRow() {
      const speakers = this.program.speaker.length
      if (speakers === 1) {
        return 12
      }
      else if(speakers === 2) {
        return 6
      }
      else {
        return 4
      }
    }
  },
  methods: {
    showSpeaker(id) {
      console.log(123)
      const index = _.findIndex(this.program.speaker, item => item.id === id)
      if (index !== -1) {
        this.activeSpeaker = this.program.speaker[index]
        this.$modal.show('speaker_' + this.program.id)
        if (this.program.speaker[index + 1]) {
          this.nextSpeaker = this.program.speaker[index + 1]
        }
        else {
          this.nextSpeaker = null
        }
        if (this.program.speaker[index - 1]) {
          this.prevSpeaker = this.program.speaker[index - 1]
        }
        else {
          this.prevSpeaker = null
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .program {
    &__info {
      &::v-deep {
        ul {

        }

        h3 {
          font-size: rem(21);
          color: #282938;
          font-weight: 500;
        }
      }
    }
    &__avatar {
      width: 110px;
      height: 110px;
      //margin: 0 auto;
      border-radius: 10em;
      position: relative;
      overflow: hidden;
      min-width: 110px;
      @include media(992) {
        margin: 0 auto;
      }
      @include media(768) {
        width: 80px;
        height: 80px;
        min-width: 80px;
      }
      &_small {
        width: 90px;
        height: 90px;
        min-width: 90px;
      }
      img {
        max-width: 100%;
        vertical-align: top;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
    &__speaker {
      cursor: pointer;
    }
    &__name {
      font-weight: 700;
      padding-top: rem(14);
      color: #1D2130;
    }
    &__cont {
      padding-left: rem(16);
      @include media(992) {
        text-align: center;
        padding-left: 0;
      }
    }
    &__job {
      font-weight: 700;
      font-size: rem(14);
      color: #1D2130;
      opacity: 0.7;
    }
    &__link {
      a {
        text-decoration: none;
        color: #52B5E0;
        font-size: rem(12);
        font-weight: 300;
      }
    }
    &__title {
      font-weight: 500;
      font-size: rem(18);
      color: #282938;
    }
    &__theme {
      padding-top: rem(5);
      &::v-deep {
        ul {
          padding: 0;
          margin: 0;
        }

        @include media(768) {
          padding: 0 20px;
        }
      }
    }
  }
  .speaker {
    &__name {
      font-weight: 500;
      font-size: rem(18);
      padding-top: rem(12);
      color: #1D2130;
    }
    &__job {
      font-weight: 400;
      font-size: rem(14);
      line-height: rem(16);
      color: #1D2130;
      opacity: 0.7;
      padding-top: rem(6);
    }
    &__line {
      border-bottom: 2px solid #ECECF1;
      padding: rem(6) 0;
    }
    &__text {
      overflow: hidden;
      padding-top: rem(12);
      font-size: rem(14);
      font-weight: 300;
      color: #282938;
      &::v-deep {
        * {
          font-size: rem(14);
          font-weight: 300;
          color: #282938;
        }
      }
    }
    &__replace {
      cursor: pointer;
      margin-top: rem(10);
      &_prev {
        svg {
          transform: rotate(180deg);
        }
      }
      span {
        font-size: rem(14);
        font-weight: 200;
        color: #282938;
      }
      svg {
        height: auto;
        vertical-align: middle;
      }
    }
  }
</style>
