<template>
    <validation-provider tag="div" :rules="form.rules" v-slot="{errors}" class="form__item"
                         :name="form.nameRus"
    >
        <p class="form__title">{{form.placeholder}}</p>
        <select :name="form.name" @change="changeVal" class="form__select">
            <option :value="item.value" v-for="(item, index) in form.values" :key="index">{{item.nameRus}}</option>
        </select>
        <div class="form__error" v-if="errors.length && !success">{{errors[0]}}</div>
    </validation-provider>
</template>

<script>
    export default {
        name: "FormSelect",
        props: ['form', 'index', 'success'],
        methods: {
            changeVal(e) {
                console.log(e.target.value)
                this.$emit('changeVal', {
                    val: e.target.value,
                    index: this.index
                })
            }
        }
    }
</script>

<style scoped lang="scss">

</style>