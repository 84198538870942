<template>
  <nav class="nav">
    <ul>
      <li v-if="$store.getters.isAuth">
        <router-link :to="{name: 'Telecast'}">Смотреть видео</router-link>
      </li>
      <li>
        <router-link :to="{name: 'Home', hash: '#section-program'}" v-if="$route.name !== 'Home'">Программа
        </router-link>
        <a href="#" v-scroll-to="'#section-program'" v-else>Программа</a>
      </li>
      <li>
        <router-link :to="{name: 'Home', hash: '#section-partners'}" v-if="$route.name !== 'Home'">Партнеры
        </router-link>
        <a href="#" v-scroll-to="'#section-partners'" v-else>Партнеры</a>
      </li>
      <li>
        <router-link :to="{name: 'Material'}">Материалы партнеров</router-link>
      </li>
      <li>
        <router-link :to="{name: 'Home', hash: '#section-faq'}" v-if="$route.name !== 'Home'">FAQ
        </router-link>
        <a href="#" v-scroll-to="'#section-faq'" v-else>FAQ</a>
      </li>
      <!--      <li>-->
      <!--        <a href="#" v-scroll-to="'#section-votes'">Опросы</a>-->
      <!--      </li>-->
      <li v-if="$store.getters.isAuth">
        <a href="#" class="exit" @click.prevent="logout">
          Выйти
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7" filter="url(#filter0_d_434_840)">
              <path
                  d="M12.4659 12.474L7.43698 7.44508L2.28357 12.5985L1.02772 11.3426L6.18113 6.18923L1.1522 1.1603L2.15909 0.153409L7.18802 5.18234L12.3414 0.0289291L13.5973 1.28478L8.44387 6.43819L13.4728 11.4671L12.4659 12.474Z"
                  fill="#FE5959"/>
            </g>
            <defs>
              <filter id="filter0_d_434_840" x="0.0273438" y="0.0289307" width="14.5703" height="14.5695"
                      filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                               result="hardAlpha"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="0.5"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.416667 0 0 0 0 0.460692 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_434_840"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_434_840" result="shape"/>
              </filter>
            </defs>
          </svg>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "MenuTop",
  methods: {
    logout() {
      this.$cookie.delete('token')
      this.$store.commit('changeUser')
      this.$cookie.delete('rememberToken')
    }
  }
}
</script>

<style scoped lang="scss">
.nav {
  position: absolute;
  top: 35px;
  right: 0;
  z-index: 10;
  @include media(768) {
    display: none;
  }

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      padding: 0 rem(15);

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      a {
        text-decoration: none;
        color: #fff;
        font-weight: 400;
        position: relative;

        &:hover {
          &::before {
            content: '';
            position: absolute;
            top: 100%;
            margin-top: rem(10);
            background: #59B980;
            box-shadow: 0px 1px 1px #58FF9C;
            border-radius: 1px;
            height: 2px;
            left: 0;
            right: 0;
          }
        }
      }

      .exit {
        margin-left: 8vw;
      }
    }
  }
}
</style>
