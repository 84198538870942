import axios from "../plugins/axios"
import Cookie from 'js-cookie'

export default async ({next, store}) => {
    try {
        if (Cookie.get('rememberToken') && !store.getters.isAuth) {
            const resp = await axios.post('auth/refresh', {token: Cookie.get('rememberToken')})
            axios.defaults.headers.common = {'Authorization': `Bearer ${resp.data.token}`}
            await Cookie.set('token', resp.data.token, { expires: 1/24 })
            await store.commit('changeUser', resp.data.user)
            await Cookie.set('rememberToken', resp.data.rememberToken, { expires: 60 })
        }
        return next()
    }
    catch (e) {
        Cookie.remove('rememberToken')
        return next()
    }
}