<template>
  <validation-provider class="form__item" tag="div" :rules="form.rules" v-slot="{errors, touched}" :name="form.nameRus">
    <div class="row ">
      <div class="col-12 col-sm-12">
        <div tag="div" class="">
          <input :type="form.type" v-model="email"
                 :ref="form.name"
                 :vid="form.name"
                 @change="changeVal"
                 :placeholder="form.placeholder" :name="form.name" :value="form.value" class="form__input"
                 :class="errors.length && !success && touched ? 'form__input_error' : ''">
          <div class="form__error" v-if="errors.length && !success && touched">{{ errors[0] }}</div>
          <div class="form__error" v-if="unique">E-mail уже занят</div>

        </div>
      </div>
      <!--      <div class="col-12 col-sm-4">-->
      <!--        <button class="button button_blue button_phone" @click.prevent="showForm = true" :disabled="errors.length || !touched"-->
      <!--                v-if="status === null">Подтвердить-->
      <!--        </button>-->
      <!--        <button class="button button_phone button_green" disabled v-else-if="status === true">Подтверждено</button>-->
      <!--        <transition name="fade">-->
      <!--          <validate-form v-if="showForm" :phone="email" @goodVerify="goodVerify"-->
      <!--                         @closeModal="showForm = false"></validate-form>-->
      <!--        </transition>-->
      <!--      </div>-->
    </div>
  </validation-provider>
</template>


<script>

// import ValidateForm from "./ValidateForm";

export default {
  name: "FormEmail",
  // components: {ValidateForm},
  props: ['form', 'valid', 'index', 'success', 'unique'],
  data() {
    return {
      email: '',
      isValid: false,
      status: null,
      showForm: false
    }
  },
  methods: {
    changeInput(e, valid) {
      this.isValid = valid.valid
    },
    goodVerify() {
      this.showForm = false
      this.status = true
      this.$emit('changeVal', {
        val: this.email,
        index: this.index
      })
      this.$emit('phoneVerify')
    },
    changeVal(e) {
      this.$emit('changeVal', {
        val: e.target.value,
        index: this.index
      })
    }
  },
  mounted() {
    this.$emit('phoneVerify')
  }
}
</script>

<style scoped lang="scss">

</style>