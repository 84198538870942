<template>
  <div class="nav__wrap" v-show="showMenu || $route.name !== 'Home'" :class="$route.name !== 'Home' ? 'nav__wrap_top' : ''">
    <div class="nav__button" @click="toggleMenu = !toggleMenu">
      <svg width="35" height="21" viewBox="0 0 35 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="3.05176e-05" width="35" height="3" rx="1" fill="white"/>
        <rect y="9.00003" width="35" height="3" rx="1" fill="white"/>
        <rect y="18" width="35" height="3" rx="1" fill="white"/>
      </svg>
    </div>
    <nav class="nav" :class="toggleMenu ? 'nav_toggle' : ''">
      <ul>
        <li v-if="$store.getters.isAuth">
          <router-link :to="{name: 'Telecast'}">Смотреть видео</router-link>
        </li>
        <li>
          <router-link :to="{name: 'Home', hash: '#section-program'}" v-if="$route.name !== 'Home'">Программа
          </router-link>
          <a href="#" v-scroll-to="'#section-program'" v-else>Программа</a>
        </li>
        <li>
          <router-link :to="{name: 'Home', hash: '#section-partners'}" v-if="$route.name !== 'Home'">Партнеры
          </router-link>
          <a href="#" v-scroll-to="'#section-partners'" v-else>Партнеры</a>
        </li>
        <li>
          <router-link :to="{name: 'Material'}">Материалы партнеров</router-link>
        </li>
        <li>
          <router-link :to="{name: 'Home', hash: '#section-faq'}" v-if="$route.name !== 'Home'">FAQ
          </router-link>
          <a href="#" v-scroll-to="'#section-faq'" v-else>FAQ</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "MenuFixed",
  data() {
    return {
      showMenu: false,
      toggleMenu: false
    }
  },
  methods: {
    showScroll() {
      if ((window.scrollY + (window.scrollY * 0.2)) >= window.innerHeight) {
        this.showMenu = true
      }
      else {
        this.showMenu = false
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.showScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.showScroll)
  }
}
</script>

<style scoped lang="scss">
  .nav {
    position: absolute;
    z-index: 100;
    top: 0;
    right: -100vw;
    height: 100%;
    background: #EAF6FB;
    border-radius: 10px 0px 0px 10px;
    padding: 0 rem(30);
    transition: all .25s;
    @include media(768) {
      padding:rem(10);
      height: auto;
    }
    &_toggle {
      right: 60px;
      @include media(768) {
        right: 0;
      }
    }
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      margin: 0;
      list-style-type: none;
      @include media(768) {
        margin-right: rem(40);
        flex-direction: column;
      }
      li {
        padding: 0 rem(15);
        @include media(768) {
          padding: 0 0 rem(10);
        }
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
          @include media(768) {
            padding-bottom: 0;
          }
        }
        a {
          text-decoration: none;
          color: #00476D;
          font-weight: 400;
          position: relative;
          &:hover {
            &::before {
              content: '';
              position: absolute;
              top: 100%;
              margin-top: rem(10);
              background: #59B980;
              box-shadow: 0px 1px 1px #58FF9C;
              border-radius: 1px;
              height: 2px;
              left: 0;
              right: 0;
            }
          }
        }
      }
    }
    &__wrap {
      position: fixed;
      top: 30px;
      right: 0;
      z-index: 1000;

      @include media(768) {
        display: block !important;
      }
      &_top {
        top: 80px;
        @include media(768) {
          display: block;
        }
      }
    }
    &__button {
      background: linear-gradient(114.27deg, #52B5E0 -30.13%, #00B2FF 65.54%);
      border-radius: 10px 0px 0px 10px;
      padding: rem(20);
      cursor: pointer;
      position: relative;
      z-index: 101;
      @include media(768) {
        padding: rem(10);
        svg {
          max-width: 25px;
          height: auto;
        }
      }
    }
  }
</style>
