import Vue from 'vue'
import VueTelInput from 'vue-tel-input'
import {localize, ValidationObserver, ValidationProvider, extend} from 'vee-validate'
import VueMeta from 'vue-meta'

import * as rules from 'vee-validate/dist/rules';
import ru from 'vee-validate/dist/locale/ru.json'

import axios from './axios'
import Notifications from 'vue-notification'
import VueSlideToggle from 'vue-slide-toggle'
import VueSocketIO from 'vue-socket.io';
import VModal from 'vue-js-modal'
import socketio from 'socket.io-client'


// const SocketInstance = socketio('http://localhost:3002')

import VueCookie from 'vue-cookie'


localize('ru', ru)

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.use(VueTelInput, {
    inputOptions: {
        placeholder: 'Укажите свой номер  телефона',
        styleClasses: 'form__input form__input_phone'
    },
    validCharactersOnly: true
})

Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule])
})

Vue.prototype.$axios = axios

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(Notifications)
Vue.use(VueCookie)
Vue.use(VueSlideToggle)
Vue.use(new VueSocketIO({
    debug: process.env.NODE_ENV !== 'production',
    connection: socketio(process.env.VUE_APP_SOCKET_URL),
    options: {
        withCredentials: true,
        allowEIO3: true
    }
}))
// Vue.use(VueSocketIO, socketio(process.env.VUE_APP_SOCKET_URL))
Vue.use(VModal)

export default Vue