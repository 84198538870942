<template>
    <div class="carousel">
        <div class="carousel__wrapper">
            <slick ref="slick" :options="slickOptions">
                <div class="carousel__item" v-for="(slide, index) in getCarousel" :key="index">
                    <div class="carousel__icon" v-if="slide.content.icon" v-html="slide.content.icon"></div>
                    <h2 class="carousel__title">
                      {{slide.content.name}}
                    </h2>
                    <div class="carousel__desc" v-html="slide.content.text"></div>
                </div>
            </slick>
        </div>
        <div class="carousel__arrows d-flex justify-content-end" v-if="getCarousel.length > 1">
            <div class="carousel__arrow carousel__arrow_prev" @click="$refs.slick.prev()">
                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="21" cy="21" r="21" fill="#7F7F7F"/>
                    <path d="M12 20.875H30" stroke="#F4F6FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22.125 13L30 20.875L22.125 28.75" stroke="#F4F6FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="carousel__arrow carousel__arrow_next" @click="$refs.slick.next()">
                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="21" cy="21" r="21" fill="#7F7F7F"/>
                    <path d="M12 20.875H30" stroke="#F4F6FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22.125 13L30 20.875L22.125 28.75" stroke="#F4F6FC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
    import Slick from 'vue-slick'
    export default {
        name: "HomeSlider",
        data() {
            return {
                slickOptions: {
                    slidesToShow: 1,
                    infinite: true,
                    arrows: false
                },
            }
        },
        computed: {
          getCarousel() {
            if (this.$store.getters.getContent) {
              return this.$store.getters.getContentByType('slider')
            }
            return []
          }
        },
        components: {
            Slick
        }
    }
</script>

<style scoped lang="scss">
    .carousel {
        max-width: 431px;
        @include media(992) {
            max-width: 100%;
        }
        @include media(768) {
            padding-top: rem(0);
            padding-bottom: rem(20);
        }
        &__wrapper {
            background: #F4F6FC;
            border-radius: 10px;
            padding: 50px;
            @include media(992) {
                padding: rem(25);
            }
        }
        &__item {

        }
        &__title {
            font-style: normal;
            font-weight: 500;
            font-size: rem(21);
            color: #282938;
            padding-bottom: 10px;
        }
        &__desc {
            font-style: normal;
            font-weight: normal;
            font-size: rem(16);
            color: #282938;
            opacity: 0.7;
        }
        &__arrows {
            margin-left: auto;
            padding-top: 10px;
        }
        &__arrow {
            cursor: pointer;
            &_prev {
                margin-right: 10px;
                svg {
                    transform: rotate(180deg);
                }
            }
            &:hover {
                svg {
                    circle {
                        fill: #fff;
                    }
                    path {
                        stroke: #7F7F7F;
                    }
                }
            }
        }
        &__icon {
          padding-bottom: 14px;
          &::v-deep svg {
            height: auto;
            vertical-align: top;
            max-width: 100%;
          }
        }
    }
</style>