<template>
  <div class="page">
    <div class="page__top">
      <home-top></home-top>
<!--      <home-bottom v-if="!$store.getters.isAuth"></home-bottom>-->
    </div>
    <transition
        name="slide"
        enter-active-class="slideInRight"
        leave-active-class="slideOutRight">
      <register v-show="getModal === 'register'" v-if="!$store.getters.isAuth"></register>
    </transition>
    <transition
        name="slide"
        enter-active-class="slideInRight"
        leave-active-class="slideOutRight">
      <login v-show="getModal === 'login'" v-if="!$store.getters.isAuth"></login>
    </transition>
    <transition
        name="slide"
        enter-active-class="slideInRight"
        leave-active-class="slideOutRight">
      <forgot-password v-show="getModal === 'forgot'" v-if="!$store.getters.isAuth"></forgot-password>
    </transition>
    <program id="section-program"></program>
<!--    <votes v-if="isAuth"></votes>-->
    <partners></partners>
    <f-a-q></f-a-q>
  </div>
</template>

<script>
// @ is an alias to /src

import HomeTop from "../components/Home/HomeTop";
import Register from "../components/Auth/Register";
// import HomeBottom from "../components/Home/HomeBottom";
import Login from "../components/Auth/Login";
import FAQ from "../components/Home/FAQ";
import Program from "../components/Home/Program";
import ForgotPassword from "../components/Auth/ForgotPassword";
import Partners from "../components/Home/Partners";
// import Votes from "../components/Home/Votes";

export default {
  name: 'Home',
  components: {
    // Votes,
    Partners,
    ForgotPassword,
    Program,
    FAQ,
    Login,
    // HomeBottom,
    Register,
    HomeTop
  },
  computed: {
    isAuth() {
      return this.$store.getters.isAuth
    },
    getModal() {
      return this.$store.getters.getRightModal
    }
  },
  methods: {
    clickToCloseModal(target) {
      let classes = target && target.classList ? target.classList.value : ''
      if (classes.indexOf('button') === -1) {
        if (classes.indexOf('page') !== -1) {
          return this.$store.commit('changeRightModal', null)
        }
        if (classes.indexOf('callback') === -1 && target && target.parentNode) {
          return this.clickToCloseModal(target.parentNode)
        }
      }
    }
  },
  watch: {
    getModal() {
      if (this.getModal !== null) {
        document.addEventListener('click', e => this.clickToCloseModal(e.target))
      } else {
        document.removeEventListener('click', this.clickToCloseModal)
      }
    }
  },
  mounted() {
    if (this.$route.hash) {
      this.$scrollTo(this.$route.hash)
    }
  }
}
</script>
<style lang="scss" scoped>
.page {
  min-height: 100vh;

  &__top {
    overflow-y: hidden;
  }
}
</style>
