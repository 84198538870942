import Vue from 'vue'
import Vuex from 'vuex'
import axios from "../plugins/axios"
import _ from 'lodash'
import moment from "moment-timezone";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {id: 1},
        rightModal: null,
        event: {
            "id": 1,
            "name": "АСТРАКОНФ-2022: ИТ-ТРАНСФОРМАЦИЯ",
            "description": "<p><strong>ЕЖЕГОДНАЯ практическая конференция</strong></p><p><span class=\"ql-size-small\">Место проведения: г. Москва, Ленинградский проспект, д. 36 стр. 33, Петровский Парк Конгресс Отель (Хаятт Ридженси Москва Петровский Парк)</span></p><p>Практическая конференция для представителей госсектора и бизнеса, посвященная цифровизации и импортозамещению в сфере ИТ. Призвана продемонстрировать уже проверенные, доказавшие свою эффективность решения и практические кейсы по миграции с зарубежных продуктов на отечественные, а также представить проекты, реализованные на базе отечественной операционной системы Astra Linux.</p><p><strong>Формат - офлайн-мероприятие с трансляцией в прямом эфире.</strong></p>",
            "dateStart": "2022-04-21 07:00:00",
            "createdAt": "2022-03-02T17:21:52.000Z",
            "updatedAt": "2022-04-20T13:32:09.000Z"
        },
        settings: [
            {
                "id": 1,
                "name": "social_fb,social_inst",
                "nameRus": "фейсбук",
                "value": "",
                "type": "client",
                "createdAt": "2022-03-08T12:57:54.000Z",
                "updatedAt": "2022-03-21T15:37:08.000Z"
            }, {
                "id": 2,
                "name": "social_twitter",
                "nameRus": "Твиттер",
                "value": "https://twitter.com/astralinuxOS",
                "type": "client",
                "createdAt": "2022-03-08T12:59:20.000Z",
                "updatedAt": "2022-03-08T12:59:20.000Z"
            }, {
                "id": 3,
                "name": "social_inst",
                "nameRus": "инстаграмм",
                "value": "https://vk.com/astralinux",
                "type": "client",
                "createdAt": "2022-03-08T13:00:23.000Z",
                "updatedAt": "2022-03-21T15:44:03.000Z"
            }, {
                "id": 4,
                "name": "social_fb",
                "nameRus": "ФБ",
                "value": "https://vk.com/astralinux",
                "type": "client",
                "createdAt": "2022-03-08T13:59:08.000Z",
                "updatedAt": "2022-03-21T15:44:08.000Z"
            }, {
                "id": 5,
                "name": "phone_first",
                "nameRus": "Тел середина",
                "value": "тел.: +7 495 369 48 16 доб. 602",
                "type": "client",
                "createdAt": "2022-03-08T13:59:47.000Z",
                "updatedAt": "2022-03-11T12:51:00.000Z"
            }, {
                "id": 6,
                "name": "name_first",
                "nameRus": "Имя середина",
                "value": "Ольга Яшенкова<br/>Руководитель отдела маркетинговых коммуникаций",
                "type": "client",
                "createdAt": "2022-03-08T14:00:40.000Z",
                "updatedAt": "2022-03-11T12:52:18.000Z"
            }, {
                "id": 7,
                "name": "email_first",
                "nameRus": "почта середина",
                "value": "oyashenkova@astralinux.ru",
                "type": "client",
                "createdAt": "2022-03-08T14:02:33.000Z",
                "updatedAt": "2022-03-08T14:11:59.000Z"
            }, {
                "id": 8,
                "name": "name_second",
                "nameRus": "Имя верх",
                "value": "Наталья Шевелева<br/>Менеджер по организации мероприятий",
                "type": "client",
                "createdAt": "2022-03-08T14:02:56.000Z",
                "updatedAt": "2022-05-06T17:16:35.000Z"
            }, {
                "id": 9,
                "name": "phone_second",
                "nameRus": "Тел верх",
                "value": "тел.: +7 495 369 48 16 доб. 620",
                "type": "client",
                "createdAt": "2022-03-08T14:03:36.000Z",
                "updatedAt": "2022-05-06T17:16:36.000Z"
            }, {
                "id": 10,
                "name": "email_second",
                "nameRus": "почта верх",
                "value": "nsheveleva@astralinux.ru",
                "type": "client",
                "createdAt": "2022-03-08T14:08:20.000Z",
                "updatedAt": "2022-05-06T17:16:37.000Z"
            }, {
                "id": 11,
                "name": "name_third",
                "nameRus": "Имя низ",
                "value": "Анна Баканович<br/>PR-директор ГК «Астра»",
                "type": "client",
                "createdAt": "2022-03-08T14:09:10.000Z",
                "updatedAt": "2022-05-06T17:17:28.000Z"
            }, {
                "id": 12,
                "name": "phone_third",
                "nameRus": "Тел низ",
                "value": "тел.: +7 495 369 48 16 доб. 606",
                "type": "client",
                "createdAt": "2022-03-08T14:09:49.000Z",
                "updatedAt": "2022-05-06T17:17:29.000Z"
            }, {
                "id": 13,
                "name": "email_third",
                "nameRus": "почта низ",
                "value": "abakanovich@astralinux.ru",
                "type": "client",
                "createdAt": "2022-03-08T14:10:28.000Z",
                "updatedAt": "2022-05-06T17:17:30.000Z"
            }, {
                "id": 14,
                "name": "metrika",
                "nameRus": "Яндекс Метрика",
                "value": "87778714",
                "type": "client",
                "createdAt": "2022-03-11T10:29:36.000Z",
                "updatedAt": "2022-03-11T10:29:36.000Z"
            }, {
                "id": 15,
                "name": "social_tg",
                "nameRus": "Телеграмм",
                "value": "tg://resolve?domain=astraconf",
                "type": "client",
                "createdAt": "2022-03-11T17:52:59.000Z",
                "updatedAt": "2022-04-01T11:10:55.000Z"
            }, {
                "id": 16,
                "name": "social_youtube",
                "nameRus": "Ютуб",
                "value": "https://www.youtube.com/c/AstraLinuxRussia",
                "type": "client",
                "createdAt": "2022-03-11T17:53:33.000Z",
                "updatedAt": "2022-03-11T17:53:33.000Z"
            }, {
                "id": 17,
                "name": "social_vk",
                "nameRus": "Вконтакте",
                "value": "https://vk.com/astralinux",
                "type": "client",
                "createdAt": "2022-03-11T17:54:03.000Z",
                "updatedAt": "2022-03-11T17:54:03.000Z"
            }, {
                "id": 21,
                "name": "jivo",
                "nameRus": "Живочат",
                "value": "",
                "type": "client",
                "createdAt": "2022-04-20T20:55:43.000Z",
                "updatedAt": "2022-04-21T16:26:23.000Z"
            }],
        content: [
            {
                "id": 1,
                "type": "faq",
                "order": 1,
                "content": {
                    "name": "Что требуется для качественного просмотра трансляции?",
                    "text": "<p>Наличие стабильного и скоростного интернет-подключения — от 20 мб/с как для входящего, так и для исходящего трафика. Скорость подключения можно проверить, пройдя по следующей ссылке: <a href=\"https://www.speedtest.net/ru\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.speedtest.net/ru</a>&nbsp;.</p>"
                },
                "createdAt": "2022-03-08T11:50:25.000Z",
                "updatedAt": "2022-03-11T17:24:35.000Z"
            }, {
                "id": 2,
                "type": "faq",
                "order": 2,
                "content": {
                    "name": "Могу ли я подключиться через Wi-Fi?",
                    "text": "<p>Рекомендуем подключить устройство напрямую к кабелю Internet или находиться на минимальном расстоянии от Wi-Fi-роутера. Требуется стабильное подключение со скоростью от 20 мб/с как для входящего, так и для исходящего трафика. Скорость интернет-подключения можно проверить, пройдя по следующей ссылке: <a href=\"https://www.speedtest.net/ru\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.speedtest.net/ru</a>&nbsp;.</p>"
                },
                "createdAt": "2022-03-08T11:51:00.000Z",
                "updatedAt": "2022-03-11T17:26:26.000Z"
            }, {
                "id": 3,
                "type": "faq",
                "order": 3,
                "content": {
                    "name": "Почему у меня изображение низкого качества?",
                    "text": "<p>Во время просмотра онлайн-конференции нежелательно обновлять ваше ПО, а также использовать файлообменники (Torrent) или скачивать файлы из Интернета.</p><p>*Из-за недостаточной скорости плеер конференции перестроится на более низкое качество изображения, и качество картинки снизится.</p>"
                },
                "createdAt": "2022-03-08T11:51:32.000Z",
                "updatedAt": "2022-03-11T17:28:04.000Z"
            }, {
                "id": 5,
                "type": "faq",
                "order": 4,
                "content": {"name": "Могу ли я смотреть трансляцию с телефона?", "text": "<p>Да, это возможно.</p>"},
                "createdAt": "2022-03-08T11:56:32.000Z",
                "updatedAt": "2022-03-08T11:56:32.000Z"
            }, {
                "id": 6,
                "type": "faq",
                "order": 5,
                "content": {
                    "name": "Могу ли я передать свой код коллегам для просмотра трансляции?",
                    "text": "<p><span style=\"color: rgb(0, 0, 0);\">Обращаем ваше внимание, что персональный код </span>одновременно работает только на 1 устройстве, его передача <span style=\"color: rgb(0, 0, 0);\">запрещена.</span></p>"
                },
                "createdAt": "2022-03-08T13:45:46.000Z",
                "updatedAt": "2022-03-11T17:29:18.000Z"
            }, {
                "id": 12,
                "type": "faq",
                "order": 5,
                "content": {
                    "name": "Какие данные потребуются, чтобы подключиться к конференции и задавать вопросы?",
                    "text": "<p>Для просмотра трансляции и доступа к чату вам потребуется авторизоваться на платформе с помощью полученного от организаторов мероприятия логина и пароля.</p>"
                },
                "createdAt": "2022-03-08T13:49:42.000Z",
                "updatedAt": "2022-03-11T17:30:22.000Z"
            }, {
                "id": 7,
                "type": "faq",
                "order": 6,
                "content": {
                    "name": "Могу ли я задать вопрос спикеру?",
                    "text": "<p><span style=\"color: rgb(0, 0, 0);\">Да, такая возможность будет – необходимо выбрать чат рядом с плеером и написать в нем свои вопросы.</span></p>"
                },
                "createdAt": "2022-03-08T13:46:26.000Z",
                "updatedAt": "2022-03-11T17:31:48.000Z"
            }, {
                "id": 8,
                "type": "faq",
                "order": 6,
                "content": {
                    "name": "Что делать, если у меня остались вопросы по технической части?",
                    "text": "<p><span style=\"color: rgb(0, 0, 0);\">Вы можете обратиться в нашу службу поддержки, контакты указаны внизу страницы.&nbsp;</span></p>"
                },
                "createdAt": "2022-03-08T13:47:04.000Z",
                "updatedAt": "2022-03-11T17:33:02.000Z"
            }, {
                "id": 9,
                "type": "faq",
                "order": 6,
                "content": {
                    "name": "Будут ли разосланы презентации после трансляции?",
                    "text": "<p><span style=\"color: rgb(0, 0, 0);\">Да, все материалы будут отправлены после конференции.</span></p>"
                },
                "createdAt": "2022-03-08T13:47:44.000Z",
                "updatedAt": "2022-03-08T13:47:44.000Z"
            }, {
                "id": 10, "type": "faq", "order": 6, "content": {
                    "name": "Почему я не вижу трансляцию?",
                    "text": "<p>Возможно, вы не ввели персональный код, который был отправлен вам на e-mail, указанный при регистрации. Вернитесь на главную страницу сайта и введите код в появившемся окне.</p><p>Для просмотра трансляции рекомендуем пользоваться браузерами Yandex, Google Chrome, Microsoft Edge, Opera или Safari., Все они должны быть обновлены до актуальной версии.&nbsp;</p>"
                }, "createdAt": "2022-03-08T13:48:07.000Z", "updatedAt": "2022-03-11T17:33:34.000Z"
            }, {
                "id": 11,
                "type": "faq",
                "order": 6,
                "content": {
                    "name": "Нужны ли мне наушники с микрофоном для участия в конференции?",
                    "text": "<p>Нет, микрофон вам не потребуется. В рамках конференции не планируется общение участников в прямом эфире.</p>"
                },
                "createdAt": "2022-03-08T13:48:34.000Z",
                "updatedAt": "2022-03-11T17:34:36.000Z"
            }, {
                "id": 13,
                "type": "faq",
                "order": 7,
                "content": {
                    "name": "Через какой браузер лучше смотреть конференцию?",
                    "text": "<p>Рекомендуем пользоваться Yandex, Google Chrome, Microsoft Edge, Opera или Safari. Все браузеры должны быть обновлены до актуальной версии.</p><p>Как обратиться в техническую поддержку?</p><p>При проблемах с регистрацией, работой на платформе, просмотром трансляции, а также по другим техническим вопросам вы можете обратиться в чат технической поддержки. Окно чата всегда находится в правом нижнем углу сайта.</p>"
                },
                "createdAt": "2022-03-08T13:51:00.000Z",
                "updatedAt": "2022-03-11T17:35:20.000Z"
            }, {
                "id": 14,
                "type": "faq",
                "order": 9,
                "content": {
                    "name": "Чат технической поддержки",
                    "text": "<p>При возникновении проблем с прохождением регистрации, работой на платформе, просмотром окна трансляции и по другим техническим вопросам вы можете обратиться в чат технической поддержки. Он всегда находится в правом нижнем углу сайта.</p>"
                },
                "createdAt": "2022-03-08T13:51:26.000Z",
                "updatedAt": "2022-04-20T13:36:09.000Z"
            }],
        program: [
            {
                "id": 1,
                "name": "<p><strong> ВСТУПИТЕЛЬНОЕ СЛОВО</strong></p>",
                "time": "10:00 - 10:05",
                "content": "",
                "order": 1,
                "createdAt": "2022-03-14T11:57:18.000Z",
                "updatedAt": "2022-04-04T12:19:41.000Z",
                "section_id": 1,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 1,
                    "name": "Пленарная сессия",
                    "theme": "На пути к глобальной ИТ-трансформации",
                    "order": 1,
                    "createdAt": "2022-03-14T11:55:29.000Z",
                    "updatedAt": "2022-03-14T11:55:29.000Z"
                },
                "Speaker": [{
                    "id": 26,
                    "name": "Гутин Александр",
                    "job": "Директор по маркетингу ГК «Астра»",
                    "avatar": "/images/V_Модератор_Гутин-Александр.jpg",
                    "info": "<p>Выпускник высшей школы СПбГУ, дипломированный бизнес- тренер, отвечает за продуктовую политику, стратегическое развитие и продвижение брендов компании. Лично провел более тысячи мероприятий и презентаций, неоднократно выступал экспертом на независимых конференциях и круглых столах, посвященных актуальным вопросам ИТ-индустрии России.</p>",
                    "createdAt": "2022-04-02T20:08:47.000Z",
                    "updatedAt": "2022-04-04T06:04:32.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-04T06:04:42.000Z",
                        "updatedAt": "2022-04-04T06:04:42.000Z",
                        "program_id": 1,
                        "speaker_id": 26
                    }
                }]
            }, {
                "id": 2,
                "name": "<p><strong> ВСТУПИТЕЛЬНОЕ СЛОВО</strong></p>",
                "time": "12:00 - 12:05",
                "content": "<h3><br></h3>",
                "order": 1,
                "createdAt": "2022-03-14T12:01:03.000Z",
                "updatedAt": "2022-04-04T12:19:45.000Z",
                "section_id": 2,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 2,
                    "name": "Секция 1",
                    "theme": "Практика импортозамещения",
                    "order": 2,
                    "createdAt": "2022-03-14T11:58:59.000Z",
                    "updatedAt": "2022-04-02T18:52:17.000Z"
                },
                "Speaker": [{
                    "id": 29,
                    "name": "Спирькова Анастасия",
                    "job": "Заместитель директора по маркетингу ГК «Астра»",
                    "avatar": "/images/V_Астра_Спирькова-Анастасия.jpg",
                    "info": "<p>Дипломированный бизнес-тренер, сертифицированный медиатор, мастер НЛП, победитель конкурса «Бизнес-тренер России». Лично провела более тысячи обучений и презентаций, неоднократно выступала экспертом на независимых конференциях и круглых столах, посвященных актуальным вопросам ИТ-индустрии.</p>",
                    "createdAt": "2022-04-03T09:26:37.000Z",
                    "updatedAt": "2022-04-06T21:19:14.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-04T06:13:24.000Z",
                        "updatedAt": "2022-04-04T06:13:24.000Z",
                        "program_id": 2,
                        "speaker_id": 29
                    }
                }]
            }, {
                "id": 4,
                "name": "<p><strong> ВСТУПИТЕЛЬНОЕ СЛОВО</strong></p>",
                "time": "12:00 - 12:05",
                "content": "<h3><br></h3>",
                "order": 1,
                "createdAt": "2022-03-14T12:03:32.000Z",
                "updatedAt": "2022-04-04T12:19:49.000Z",
                "section_id": 3,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 3,
                    "name": "Секция 2",
                    "theme": "ИТ-решения для цифровизации",
                    "order": 3,
                    "createdAt": "2022-03-14T11:59:20.000Z",
                    "updatedAt": "2022-04-02T18:52:18.000Z"
                },
                "Speaker": [{
                    "id": 32,
                    "name": "Мылицын Роман",
                    "job": "Руководитель направления перспективных исследований ГК «Астра»",
                    "avatar": "/images/V_Астра_Мылицын-Роман.jpg",
                    "info": "<p>С 2005 года по 2008 год работал прокурором отдела в управлении Генеральной прокуратуры РФ в УрФО. 2008-2012гг. – ведущий юрисконсульт в Корпорации \"Маяк\". С 2012г. действует как практикующий юрист, адвокат. 2013-2015гг. – директор \"Альянс Проф\". В 2017г. - директор центра компетенции НПО «РусБИТех». С 2019 года – руководитель направлений ГК «Астра».</p>",
                    "createdAt": "2022-04-03T09:41:24.000Z",
                    "updatedAt": "2022-04-13T21:34:24.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-04T06:17:48.000Z",
                        "updatedAt": "2022-04-04T06:17:48.000Z",
                        "program_id": 4,
                        "speaker_id": 32
                    }
                }]
            }, {
                "id": 35,
                "name": "<p><strong> ВСТУПИТЕЛЬНОЕ СЛОВО</strong></p>",
                "time": "12:00 - 12:05",
                "content": "",
                "order": 1,
                "createdAt": "2022-04-02T18:10:06.000Z",
                "updatedAt": "2022-04-04T12:20:01.000Z",
                "section_id": 4,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 4,
                    "name": "Секция 3",
                    "theme": "Как это работает: демонстрация решений",
                    "order": 4,
                    "createdAt": "2022-03-14T11:59:41.000Z",
                    "updatedAt": "2022-04-02T18:52:19.000Z"
                },
                "Speaker": [{
                    "id": 51,
                    "name": "Соболев Александр",
                    "job": "Заместитель начальника отдела пресейла ГК «Астра»",
                    "avatar": "/images/V_ASTRA_Sobolev.jpg",
                    "info": "",
                    "createdAt": "2022-04-09T15:39:33.000Z",
                    "updatedAt": "2022-04-13T21:35:34.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-09T15:49:24.000Z",
                        "updatedAt": "2022-04-09T15:49:24.000Z",
                        "program_id": 35,
                        "speaker_id": 51
                    }
                }]
            }, {
                "id": 8,
                "name": "<p><strong> ВСТУПИТЕЛЬНОЕ СЛОВО</strong></p>",
                "time": "12:00 - 12:05",
                "content": "<h3><br></h3>",
                "order": 1,
                "createdAt": "2022-03-14T12:05:29.000Z",
                "updatedAt": "2022-04-04T12:19:55.000Z",
                "section_id": 5,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 5,
                    "name": "Секция 4",
                    "theme": "Система воспроизводства ИТ-кадров",
                    "order": 5,
                    "createdAt": "2022-03-14T12:00:01.000Z",
                    "updatedAt": "2022-04-14T12:59:18.000Z"
                },
                "Speaker": [{
                    "id": 33,
                    "name": "Кирдяшов Федор ",
                    "job": "Директор департамента образования ГК «Астра»",
                    "avatar": "/images/V_Астра_Кирдяшов-Федор.jpg",
                    "info": "<p>Руководитель комитета РУССОФТ по развитию, подготовке и переподготовке кадров. Кандидат технических наук, эксперт в области образования и развития персонала. На протяжении нескольких лет занимается организацией процессов подготовки кадров для отрасли информационных технологий. Имеет более 50 научно-методических работ в том числе и по операционным системам. Является автором модели профессиональной магистратуры, с её реализацией в организациях высшего образования.&nbsp;</p>",
                    "createdAt": "2022-04-03T09:44:18.000Z",
                    "updatedAt": "2022-04-04T21:02:36.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-03T19:47:25.000Z",
                        "updatedAt": "2022-04-03T19:47:25.000Z",
                        "program_id": 8,
                        "speaker_id": 33
                    }
                }]
            }, {
                "id": 10,
                "name": "<p><strong>ПРОЕКТ X10: ВЫЗОВЫ И НОВЫЕ ВОЗМОЖНОСТИ</strong></p>",
                "time": "10:05 - 10:20",
                "content": "<p> </p>",
                "order": 2,
                "createdAt": "2022-03-14T12:06:49.000Z",
                "updatedAt": "2022-04-04T12:20:40.000Z",
                "section_id": 1,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 1,
                    "name": "Пленарная сессия",
                    "theme": "На пути к глобальной ИТ-трансформации",
                    "order": 1,
                    "createdAt": "2022-03-14T11:55:29.000Z",
                    "updatedAt": "2022-03-14T11:55:29.000Z"
                },
                "Speaker": [{
                    "id": 27,
                    "name": "Сивцев Илья",
                    "job": "Генеральный директор ГК «Астра»",
                    "avatar": "/images/V_Астра_Сивцев-Илья.jpg",
                    "info": "",
                    "createdAt": "2022-04-03T09:19:23.000Z",
                    "updatedAt": "2022-04-04T06:08:50.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-04T06:12:19.000Z",
                        "updatedAt": "2022-04-04T06:12:19.000Z",
                        "program_id": 10,
                        "speaker_id": 27
                    }
                }]
            }, {
                "id": 29,
                "name": "<p><strong>ПЕРЕХОД НА ОТЕЧЕСТВЕННЫЕ СХД И ВИРТУАЛИЗАЦИЮ С «АЭРОДИСК» БЫСТРО И БЕЗ БОЛИ: ПРОИЗВЕДЁМ, ОТГРУЗИМ, НАСТРОИМ, ОБУЧИМ</strong></p>",
                "time": "12:05 - 12:25",
                "content": "<p> </p>",
                "order": 2,
                "createdAt": "2022-04-02T12:02:05.000Z",
                "updatedAt": "2022-04-15T20:46:12.000Z",
                "section_id": 2,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 2,
                    "name": "Секция 1",
                    "theme": "Практика импортозамещения",
                    "order": 2,
                    "createdAt": "2022-03-14T11:58:59.000Z",
                    "updatedAt": "2022-04-02T18:52:17.000Z"
                },
                "Speaker": [{
                    "id": 38,
                    "name": "Володкович Вячеслав",
                    "job": "Генеральный директор компании «АЭРОДИСК»",
                    "avatar": "/images/V_AERODISK_Volodkovich.jpg",
                    "info": "<p>Более 15-ти лет работает в ИТ-отрасли. На протяжении своей карьеры занимал различные должности в российских и международных ИТ-компаниях – был инженером, системным архитектором, CIO. Сейчас Вячеслав является учредителем и сооснователем нескольких российских ИТ-компаний, в том числе компании АЭРОДИСК – производителя российских инновационных решений в области хранения данных и виртуализации.</p>",
                    "createdAt": "2022-04-06T21:25:17.000Z",
                    "updatedAt": "2022-04-08T21:50:07.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-07T07:33:15.000Z",
                        "updatedAt": "2022-04-07T07:33:15.000Z",
                        "program_id": 29,
                        "speaker_id": 38
                    }
                }]
            }, {
                "id": 5,
                "name": "<p><strong>РАЗВИТИЕ ГК </strong>«<strong>АСТРА</strong>»<strong>: ПРОДУКТЫ И РЕШЕНИЯ</strong></p>",
                "time": "12:05 - 12:25",
                "content": "<h3><br></h3>",
                "order": 2,
                "createdAt": "2022-03-14T12:03:58.000Z",
                "updatedAt": "2022-04-12T15:49:02.000Z",
                "section_id": 3,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 3,
                    "name": "Секция 2",
                    "theme": "ИТ-решения для цифровизации",
                    "order": 3,
                    "createdAt": "2022-03-14T11:59:20.000Z",
                    "updatedAt": "2022-04-02T18:52:18.000Z"
                },
                "Speaker": [{
                    "id": 49,
                    "name": "Тюрин Алексей",
                    "job": "Заместитель директора департамента внедрения и сопровождения ГК «Астра»",
                    "avatar": "/images/V_ASTRA_Tyrin.jpg",
                    "info": "",
                    "createdAt": "2022-04-09T15:37:22.000Z",
                    "updatedAt": "2022-04-13T21:35:10.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-09T15:45:50.000Z",
                        "updatedAt": "2022-04-09T15:45:50.000Z",
                        "program_id": 5,
                        "speaker_id": 49
                    }
                }]
            }, {
                "id": 68,
                "name": "<p><strong>ПРАКТИКА ИСПОЛЬЗОВАНИЯ ЧАСТНОГО ЗАЩИЩЕННОГО ОБЛАКА НА РОССИЙСКОЙ ВЫЧИСЛИТЕЛЬНОЙ ТЕХНИКЕ</strong></p>",
                "time": "12:05 - 12:40",
                "content": "",
                "order": 2,
                "createdAt": "2022-04-13T06:36:26.000Z",
                "updatedAt": "2022-04-15T06:45:42.000Z",
                "section_id": 4,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 4,
                    "name": "Секция 3",
                    "theme": "Как это работает: демонстрация решений",
                    "order": 4,
                    "createdAt": "2022-03-14T11:59:41.000Z",
                    "updatedAt": "2022-04-02T18:52:19.000Z"
                },
                "Speaker": [{
                    "id": 69,
                    "name": "Фильченков Александр",
                    "job": "Руководитель отдела продуктового маркетинга 3 Logic Group, разработчик модельного ряда серверов, систем хранения данных и телекоммуникационного оборудования торговой марки «Гравитон»",
                    "avatar": "/images/V_3LOGIC_Filchenkov.jpg",
                    "info": "<p>На рынке вычислительной техники с 1998 года. После окончания Московского института радиоэлектроники и автоматики начал работу с формирования каналов сбыта компьютерного оборудования Sunrise, вывел на российский рынок бренды RAD и Motorola, сформировав модель сетей передачи данных по телевизионному кабелю. Разработал модельный ряд кластерных систем, адаптировав решение в комплекс с иммерсионным охлаждением для использования в ВУЗах и необорудованных помещениях. Разработал мобильный вычислительный комплекс с активным шумоподавлением.</p>",
                    "createdAt": "2022-04-15T06:27:32.000Z",
                    "updatedAt": "2022-04-20T16:00:53.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-15T06:45:42.000Z",
                        "updatedAt": "2022-04-15T06:45:42.000Z",
                        "program_id": 68,
                        "speaker_id": 69
                    }
                }]
            }, {
                "id": 20,
                "name": "<p><strong>ОБ ОРГАНИЗАЦИИ ОБРАЗОВАТЕЛЬНОГО ПРОЦЕССА ПО ИТ-НАПРАВЛЕНИЯМ ПОДГОТОВКИ В РТУ МИРЭА</strong></p>",
                "time": "12:05 - 12:25",
                "content": "<p> </p>",
                "order": 2,
                "createdAt": "2022-03-14T12:11:58.000Z",
                "updatedAt": "2022-04-04T12:20:58.000Z",
                "section_id": 5,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 5,
                    "name": "Секция 4",
                    "theme": "Система воспроизводства ИТ-кадров",
                    "order": 5,
                    "createdAt": "2022-03-14T12:00:01.000Z",
                    "updatedAt": "2022-04-14T12:59:18.000Z"
                },
                "Speaker": [{
                    "id": 34,
                    "name": "Кудж Станислав ",
                    "job": "Ректор РТУ МИРЭА",
                    "avatar": "/images/V_МИРЭА_Кудж-Станислав.jpg",
                    "info": "<p>Родился в Москве. Окончил Московский государственный университет геодезии и картографии (МИИГАиК) по специальности «Информационные системы». Доктор технических наук.</p>",
                    "createdAt": "2022-04-03T09:45:49.000Z",
                    "updatedAt": "2022-04-04T06:10:33.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-04T06:18:37.000Z",
                        "updatedAt": "2022-04-04T06:18:37.000Z",
                        "program_id": 20,
                        "speaker_id": 34
                    }
                }]
            }, {
                "id": 73,
                "name": "<p><strong>ОПЫТ ГОСКОРПОРАЦИИ «РОСКОСМОС» В ВОПРОСАХ ОБЕСПЕЧЕНИЯ ИМПОРТОНЕЗАВИСИМОСТИ ЦИФРОВЫХ И ИНФОРМАЦИОННЫХ ТЕХНОЛОГИЙ</strong></p>",
                "time": "10:20 - 10:35",
                "content": "",
                "order": 3,
                "createdAt": "2022-04-15T20:44:51.000Z",
                "updatedAt": "2022-04-15T20:44:51.000Z",
                "section_id": 1,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 1,
                    "name": "Пленарная сессия",
                    "theme": "На пути к глобальной ИТ-трансформации",
                    "order": 1,
                    "createdAt": "2022-03-14T11:55:29.000Z",
                    "updatedAt": "2022-03-14T11:55:29.000Z"
                },
                "Speaker": [{
                    "id": 72,
                    "name": "Шадрин Константин",
                    "job": "Директор Департамента цифрового развития Госкорпорации «Роскосмос»",
                    "avatar": "/images/Роскосмос_Шадрин Константин.jpg",
                    "info": "",
                    "createdAt": "2022-04-15T20:42:04.000Z",
                    "updatedAt": "2022-04-20T16:01:37.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-15T20:44:51.000Z",
                        "updatedAt": "2022-04-15T20:44:51.000Z",
                        "program_id": 73,
                        "speaker_id": 72
                    }
                }]
            }, {
                "id": 38,
                "name": "<p><strong>ИНФРАСТРУКТУРА ПОДДЕРЖКИ РАБОЧИХ МЕСТ LINUX. ОПЫТ «КРОК». ДО И ПОСЛЕ ALD PRO</strong></p>",
                "time": "12:25 - 12:45",
                "content": "",
                "order": 3,
                "createdAt": "2022-04-06T18:01:47.000Z",
                "updatedAt": "2022-04-20T12:45:01.000Z",
                "section_id": 2,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 2,
                    "name": "Секция 1",
                    "theme": "Практика импортозамещения",
                    "order": 2,
                    "createdAt": "2022-03-14T11:58:59.000Z",
                    "updatedAt": "2022-04-02T18:52:17.000Z"
                },
                "Speaker": [{
                    "id": 61,
                    "name": "Донин Александр",
                    "job": "Ведущий эксперт по импортозамещению программного обеспечения «КРОК»",
                    "avatar": "/images/V_KROK_Donin.jpg",
                    "info": "<p>Работает в КРОК с 2006 года, с 2012 является экспертом по инфраструктурным решениям. В этой роли участвовал в проектах для Сбербанка, Транснефти, Ингосстраха, ВАС РФ и др. С 2017 года развивает направление «Отечественное и свободное ПО» КРОК: занимается архитектурным сопровождением комплексных проектов; анализирует рынок российских решений и СПО; поддерживает экспертизу по актуальным требованиям законодательства в части отечественного и свободного ПО</p>",
                    "createdAt": "2022-04-13T18:03:58.000Z",
                    "updatedAt": "2022-04-13T21:37:26.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-13T18:24:36.000Z",
                        "updatedAt": "2022-04-13T18:24:36.000Z",
                        "program_id": 38,
                        "speaker_id": 61
                    }
                }]
            }, {
                "id": 51,
                "name": "<p><strong>ОТ ЖЕЛЕЗА ДО ОБЛАКОВ: ОБЗОР ПЛАТФОРМ ДЛЯ КОМПЛЕКСНОГО УПРАВЛЕНИЯ ИТ-ИНФРАСТРУКТУРОЙ ОТ РОССИЙСКОГО РАЗРАБОТЧИКА ISPSYSTEM</strong></p>",
                "time": "12:25 - 12:45",
                "content": "",
                "order": 3,
                "createdAt": "2022-04-06T20:53:34.000Z",
                "updatedAt": "2022-04-06T20:53:34.000Z",
                "section_id": 3,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 3,
                    "name": "Секция 2",
                    "theme": "ИТ-решения для цифровизации",
                    "order": 3,
                    "createdAt": "2022-03-14T11:59:20.000Z",
                    "updatedAt": "2022-04-02T18:52:18.000Z"
                },
                "Speaker": [{
                    "id": 40,
                    "name": "Царева Наталья",
                    "job": "Владелец продукта DCImanager, компания ISPsystem",
                    "avatar": "/images/V_ISPsystem_Tsareva.jpg",
                    "info": "<p>В 2014 окончила НИ ИрГТУ по специальности «Вычислительные машины, системы, комплексы в сети», в то же время работала в ИТ-отделе, где занималась настройкой оборудования. Также получила второе высшее образование в качестве переводчика сфере профессиональной коммуникации.&nbsp;С 2014 работала в ПАО “Сбербанк”&nbsp;в отделе по автоматизации выявления уязвимостей в специализированном банковском ПО: прошла путь от специалиста до руководителя сектора.&nbsp;В 2017 перешла в компанию ISPsystem в качестве владельца продукта DCImanager.</p>",
                    "createdAt": "2022-04-06T21:30:38.000Z",
                    "updatedAt": "2022-04-07T07:22:09.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-07T07:28:53.000Z",
                        "updatedAt": "2022-04-07T07:28:53.000Z",
                        "program_id": 51,
                        "speaker_id": 40
                    }
                }]
            }, {
                "id": 48,
                "name": "<p><strong>VMMANAGER 6 — РОССИЙСКАЯ ПЛАТФОРМА УПРАВЛЕНИЯ ВИРТУАЛИЗАЦИЕЙ ОТ КОМПАНИИ ISPSYSTEM</strong></p>",
                "time": "12:40 - 13:15",
                "content": "",
                "order": 3,
                "createdAt": "2022-04-06T20:47:10.000Z",
                "updatedAt": "2022-04-13T06:35:02.000Z",
                "section_id": 4,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 4,
                    "name": "Секция 3",
                    "theme": "Как это работает: демонстрация решений",
                    "order": 4,
                    "createdAt": "2022-03-14T11:59:41.000Z",
                    "updatedAt": "2022-04-02T18:52:19.000Z"
                },
                "Speaker": [{
                    "id": 42,
                    "name": "Гришин Александр",
                    "job": "Владелец продукта VMmanager, компания ISPsystem",
                    "avatar": "/images/V_ISPsystem_Grishin.jpg",
                    "info": "<p>В 2012 окончил Иркутский Государственный технический университет, в качестве специалиста в области мехатроники и робототехники. В 2015 получил магистерскую степень в области пищевой химии и биотехнологии. С 2014 работал DevOps-инженером в ПАО “Нефтяная компания Роснефть”. В 2019 присоединился к компании ISPsystem в качестве менеджера по продукту, затем возглавил команду продукта VMmanager.</p>",
                    "createdAt": "2022-04-06T21:35:11.000Z",
                    "updatedAt": "2022-04-20T16:03:22.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-07T07:27:18.000Z",
                        "updatedAt": "2022-04-07T07:27:18.000Z",
                        "program_id": 48,
                        "speaker_id": 42
                    }
                }]
            }, {
                "id": 44,
                "name": "<p><strong>МЕРЫ ГОСУДАРСТВЕННОЙ ПОДДЕРЖКИ&nbsp;ИТ-ВЕНДОРОВ В КОНТЕКСТЕ ПОДГОТОВКИ КАДРОВ И ОБЕСПЕЧЕНИЯ ОТРАСЛИ СПЕЦИАЛИСТАМИ</strong></p>",
                "time": "12:25 - 12:45",
                "content": "",
                "order": 3,
                "createdAt": "2022-04-06T20:36:58.000Z",
                "updatedAt": "2022-04-15T06:47:10.000Z",
                "section_id": 5,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 5,
                    "name": "Секция 4",
                    "theme": "Система воспроизводства ИТ-кадров",
                    "order": 5,
                    "createdAt": "2022-03-14T12:00:01.000Z",
                    "updatedAt": "2022-04-14T12:59:18.000Z"
                },
                "Speaker": [{
                    "id": 70,
                    "name": "Сурова Надежда",
                    "job": "Член Совета Совета Федерации по цифровой экономике, директор Центра компетенций «Искусственный интеллект», управляющий директор Центра компетенций «Кадры для цифровой экономики» Университета 2035",
                    "avatar": "/images/V_Surova.jpg",
                    "info": "<p>Эксперт в&nbsp;сфере прорывных для России технологий: искусственный интеллект, big data, data science, blokchain, а также EdTech, IMPACT инвестинг и социальное предпринимательство, оператор ряда мероприятий Федерального проекта «Искусственный интеллект», входит в&nbsp;состав рабочих органов при Совете Федерации ФС&nbsp;РФ и т.д. Разработчик и директор первых в России совместных с органами власти и предприятиями совместных сетевых образовательных программ по цифровым технологиям и технологическому предпринимательству с крупнеишими российскими компаниями. IT разработчик решений в сфере EdTech - блокчейн платформ экосистемы обучения кадров, бизнес - акселерации и краудфандинга проектов инновационного предпринимательства. Автор и координатор проектов нормативно – законодательных документов и программ развития, гармонизации и интеграции цифровой экономики в РФ и мире в составе Экспертного Совета Государственной Думы по цифровой экономике и блокчеин̆ технологиям, Научно - экспертного совета Совета Федерации ФС РФ, Центра компетенции по цифровой экономике и т.д.</p>",
                    "createdAt": "2022-04-15T06:30:12.000Z",
                    "updatedAt": "2022-04-15T06:30:12.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-15T06:47:10.000Z",
                        "updatedAt": "2022-04-15T06:47:10.000Z",
                        "program_id": 44,
                        "speaker_id": 70
                    }
                }]
            }, {
                "id": 71,
                "name": "<p><strong>ИМПОРТОНЕЗАВИСИМОСТЬ. ЭКОСИСТЕМЫ И КРОСС-СОВМЕСТИМОСТЬ</strong></p>",
                "time": "10:35 - 10:50",
                "content": "",
                "order": 4,
                "createdAt": "2022-04-13T18:21:48.000Z",
                "updatedAt": "2022-04-15T20:42:59.000Z",
                "section_id": 1,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 1,
                    "name": "Пленарная сессия",
                    "theme": "На пути к глобальной ИТ-трансформации",
                    "order": 1,
                    "createdAt": "2022-03-14T11:55:29.000Z",
                    "updatedAt": "2022-03-14T11:55:29.000Z"
                },
                "Speaker": [{
                    "id": 65,
                    "name": "Лошаков Антон",
                    "job": "Заместитель министра цифрового развития Новосибирской области",
                    "avatar": "/images/Министерство_Лошаков Антон.jpg",
                    "info": "",
                    "createdAt": "2022-04-13T18:22:15.000Z",
                    "updatedAt": "2022-04-19T20:30:37.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-13T18:39:48.000Z",
                        "updatedAt": "2022-04-13T18:39:48.000Z",
                        "program_id": 71,
                        "speaker_id": 65
                    }
                }]
            }, {
                "id": 11,
                "name": "<p><strong>ИМПОРТОЗАМЕЩЕНИЕ. КЛИЕНТСКИЕ ПРОДУКТЫ «ИНФОТЕКС» ПОД ASTRA LINUX</strong></p>",
                "time": "12:45 - 13:05",
                "content": "<p> </p>",
                "order": 4,
                "createdAt": "2022-03-14T12:08:06.000Z",
                "updatedAt": "2022-04-19T21:30:03.000Z",
                "section_id": 2,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 2,
                    "name": "Секция 1",
                    "theme": "Практика импортозамещения",
                    "order": 2,
                    "createdAt": "2022-03-14T11:58:59.000Z",
                    "updatedAt": "2022-04-02T18:52:17.000Z"
                },
                "Speaker": [{
                    "id": 30,
                    "name": "Пантелеев Кирилл",
                    "job": "Архитектор отдела развития продуктов компании «ИнфоТеКС»",
                    "avatar": "/images/V_Инфотекс_Пантелеев-Кирилл.jpg",
                    "info": "<p>Более 9 лет работает в компании «ИнфоТеКС». Начинал свою карьеру инженером технического сопровождения, на текущий момент является архитектором решений. Кирилл занимается проработкой решений в сфере информационной безопасности на базе продуктов ViPNet - флагманская разработка компании «ИнфоТеКС».</p>",
                    "createdAt": "2022-04-03T09:39:38.000Z",
                    "updatedAt": "2022-04-20T15:56:55.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-04T06:14:44.000Z",
                        "updatedAt": "2022-04-04T06:14:44.000Z",
                        "program_id": 11,
                        "speaker_id": 30
                    }
                }]
            }, {
                "id": 76,
                "name": "<p><strong>СИСТЕМА ВИРТУАЛИЗАЦИИ ASTRA LINUX: СЕГОДНЯ И ЗАВТРА</strong></p>",
                "time": "12:45 - 13:05",
                "content": "",
                "order": 4,
                "createdAt": "2022-04-18T20:54:22.000Z",
                "updatedAt": "2022-04-18T20:54:22.000Z",
                "section_id": 3,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 3,
                    "name": "Секция 2",
                    "theme": "ИТ-решения для цифровизации",
                    "order": 3,
                    "createdAt": "2022-03-14T11:59:20.000Z",
                    "updatedAt": "2022-04-02T18:52:18.000Z"
                },
                "Speaker": [{
                    "id": 75,
                    "name": "Осипов Юрий",
                    "job": "Директор по специальным проектам ГК «Астра»",
                    "avatar": "/images/Астра_Юрий Осипов.jpg",
                    "info": "<p>У Осипова Юрия более 15 лет опыта развития продуктового портфеля в российских и международных компаниях («Лаборатория Касперского», Microsoft, «Аладдин Р.Д.» и другие). Руководил разработкой и внедрением новых продуктов для российского и международного рынков. Участвовал в сертификации продуктов по требованиям российских регуляторов и международных стандартов. Один из первых в России сертифицированных специалистов по технологиям Microsoft (MCSE, MCT). С 2019 года возглавляет проектное направление в ГК «Астра».</p>",
                    "createdAt": "2022-04-18T20:27:11.000Z",
                    "updatedAt": "2022-04-20T16:02:00.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-18T20:54:22.000Z",
                        "updatedAt": "2022-04-18T20:54:22.000Z",
                        "program_id": 76,
                        "speaker_id": 75
                    }
                }]
            }, {
                "id": 69,
                "name": "<p><strong>СЕРВЕРНАЯ ASTRA LINUX: КАК РАЗВЕРНУТЬ ИНФРАСТРУКТУРУ «ПО КНОПКЕ»</strong></p>",
                "time": "13:15 - 13:50",
                "content": "",
                "order": 4,
                "createdAt": "2022-04-13T06:38:24.000Z",
                "updatedAt": "2022-04-13T21:50:53.000Z",
                "section_id": 4,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 4,
                    "name": "Секция 3",
                    "theme": "Как это работает: демонстрация решений",
                    "order": 4,
                    "createdAt": "2022-03-14T11:59:41.000Z",
                    "updatedAt": "2022-04-02T18:52:19.000Z"
                },
                "Speaker": [{
                    "id": 51,
                    "name": "Соболев Александр",
                    "job": "Заместитель начальника отдела пресейла ГК «Астра»",
                    "avatar": "/images/V_ASTRA_Sobolev.jpg",
                    "info": "",
                    "createdAt": "2022-04-09T15:39:33.000Z",
                    "updatedAt": "2022-04-13T21:35:34.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-13T06:38:24.000Z",
                        "updatedAt": "2022-04-13T06:38:24.000Z",
                        "program_id": 69,
                        "speaker_id": 51
                    }
                }]
            }, {
                "id": 72,
                "name": "<p><strong>ОБУЧЕНИЕ СОТРУДНИКОВ КАК ЗАЛОГ УСПЕШНОЙ РАБОТЫ ОРГАНИЗАЦИИ</strong></p>",
                "time": "12:45 - 13:05",
                "content": "",
                "order": 4,
                "createdAt": "2022-04-13T18:38:30.000Z",
                "updatedAt": "2022-04-13T18:38:30.000Z",
                "section_id": 5,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 5,
                    "name": "Секция 4",
                    "theme": "Система воспроизводства ИТ-кадров",
                    "order": 5,
                    "createdAt": "2022-03-14T12:00:01.000Z",
                    "updatedAt": "2022-04-14T12:59:18.000Z"
                },
                "Speaker": [{
                    "id": 64,
                    "name": "Валуйских Анна",
                    "job": "Business Development Manager, Softline Education",
                    "avatar": "/images/Softline Edu_Валуйских Анна.jpg",
                    "info": "<p>Более 5 лет занимается развитием партнерских отношений с российскими и зарубежными вендорами в сфере обучения для ИТ-специалистов.</p>",
                    "createdAt": "2022-04-13T18:14:16.000Z",
                    "updatedAt": "2022-04-13T19:16:25.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-13T18:38:30.000Z",
                        "updatedAt": "2022-04-13T18:38:30.000Z",
                        "program_id": 72,
                        "speaker_id": 64
                    }
                }]
            }, {
                "id": 23,
                "name": "<p><strong>ИМПОРТОЗАМЕЩЕНИЕ В НОВЫХ РЕАЛИЯХ: ЭКСПЕРТНОЕ МНЕНИЕ ICL</strong></p>",
                "time": "10:50 - 11:05",
                "content": "<p> </p>",
                "order": 5,
                "createdAt": "2022-04-02T11:19:52.000Z",
                "updatedAt": "2022-04-15T20:43:56.000Z",
                "section_id": 1,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 1,
                    "name": "Пленарная сессия",
                    "theme": "На пути к глобальной ИТ-трансформации",
                    "order": 1,
                    "createdAt": "2022-03-14T11:55:29.000Z",
                    "updatedAt": "2022-03-14T11:55:29.000Z"
                },
                "Speaker": [{
                    "id": 59,
                    "name": "Вагизов Ильдар",
                    "job": "Директор департамента продаж и маркетинга ICL Техно",
                    "avatar": "/images/V_ICL_Vagizov.jpg",
                    "info": "<p>Пришел в ICL в 2005 году. С 2018 — директор департамента продаж и маркетинга ICL Техно, — производственного предприятия ГК ICL. Под руководством Вагизова компания стала ключевым поставщиком ИТ-оборудования для федеральных проектов в области здравоохранения и образования.</p>",
                    "createdAt": "2022-04-13T06:25:55.000Z",
                    "updatedAt": "2022-04-13T18:01:56.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-13T06:26:12.000Z",
                        "updatedAt": "2022-04-13T06:26:12.000Z",
                        "program_id": 23,
                        "speaker_id": 59
                    }
                }]
            }, {
                "id": 31,
                "name": "<p><strong> МОБИЛЬНЫЕ РЕШЕНИЯ НА БАЗЕ ЗАЩИЩЕННЫХ ПЛАНШЕТОВ MIG ПОД УПРАВЛЕНИЕМ ОС ASTRA LINUX</strong></p>",
                "time": "13:05 - 13:25",
                "content": "<p> </p>",
                "order": 5,
                "createdAt": "2022-04-02T12:04:12.000Z",
                "updatedAt": "2022-04-19T21:31:00.000Z",
                "section_id": 2,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 2,
                    "name": "Секция 1",
                    "theme": "Практика импортозамещения",
                    "order": 2,
                    "createdAt": "2022-03-14T11:58:59.000Z",
                    "updatedAt": "2022-04-02T18:52:17.000Z"
                },
                "Speaker": [{
                    "id": 37,
                    "name": "Богданова Анна ",
                    "job": "Коммерческий директор Mobile Inform Group",
                    "avatar": "/images/V_MIG_Богданова-Анна.jpg",
                    "info": "<p>Одна из основателей компании Mobile Inform Group. На рынке Auto ID c 2007 г., богатый опыт реализации проектов по внедрению автоматизированных систем управления в транспорте, энергетике, ритейле, промышленности и других отраслях с применением самых передовых технологий: QR-коды, RFID, RTLS, биометрия и т.д. Отвечает за формирование комплексного продукта на базе платформы MIG, выстраивает работу с технологическими партнерами, формирует стратегию коммерческой работы компании</p>",
                    "createdAt": "2022-04-04T06:17:10.000Z",
                    "updatedAt": "2022-04-06T21:26:09.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-04T06:17:23.000Z",
                        "updatedAt": "2022-04-04T06:17:23.000Z",
                        "program_id": 31,
                        "speaker_id": 37
                    }
                }, {
                    "id": 66,
                    "name": "Кривов Дмитрий",
                    "job": "Замгенерального директора, главный конструктор компании «Алгонт»",
                    "avatar": "/images/V_Algont_Krivov.jpg",
                    "info": "",
                    "createdAt": "2022-04-13T18:28:02.000Z",
                    "updatedAt": "2022-04-15T06:43:48.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-13T18:28:29.000Z",
                        "updatedAt": "2022-04-13T18:28:29.000Z",
                        "program_id": 31,
                        "speaker_id": 66
                    }
                }]
            }, {
                "id": 15,
                "name": "<p><strong>ИНФРАСТРУКТУРА ВИРТУАЛЬНЫХ РАБОЧИХ МЕСТ TERMIDESK</strong></p>",
                "time": "13:05 - 13:25",
                "content": "<p> </p>",
                "order": 5,
                "createdAt": "2022-03-14T12:09:37.000Z",
                "updatedAt": "2022-04-18T20:44:11.000Z",
                "section_id": 3,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 3,
                    "name": "Секция 2",
                    "theme": "ИТ-решения для цифровизации",
                    "order": 3,
                    "createdAt": "2022-03-14T11:59:20.000Z",
                    "updatedAt": "2022-04-02T18:52:18.000Z"
                },
                "Speaker": [{
                    "id": 53,
                    "name": "Мухин Денис",
                    "job": "Директор по развитию бизнеса компании «Увеон»",
                    "avatar": "/images/V_UVEON_Mukhin.jpg",
                    "info": "",
                    "createdAt": "2022-04-09T16:00:13.000Z",
                    "updatedAt": "2022-04-13T21:35:56.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-09T16:00:39.000Z",
                        "updatedAt": "2022-04-09T16:00:39.000Z",
                        "program_id": 15,
                        "speaker_id": 53
                    }
                }]
            }, {
                "id": 17,
                "name": "<p><strong>ПЕРЕРЫВ</strong></p>",
                "time": "13:50 - 14:00",
                "content": "<p> </p>",
                "order": 5,
                "createdAt": "2022-03-14T12:10:41.000Z",
                "updatedAt": "2022-04-09T15:50:20.000Z",
                "section_id": 4,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 4,
                    "name": "Секция 3",
                    "theme": "Как это работает: демонстрация решений",
                    "order": 4,
                    "createdAt": "2022-03-14T11:59:41.000Z",
                    "updatedAt": "2022-04-02T18:52:19.000Z"
                },
                "Speaker": []
            }, {
                "id": 43,
                "name": "<p><strong>ОПОРНЫЙ ОБРАЗОВАТЕЛЬНЫЙ И ЕДИНЫЙ УЧЕБНО-МЕТОДОЛОГИЧЕСКИЙ ЦЕНТРЫ. ПОДГОТОВКА ВЫСОКОКВАЛИФИЦИРОВАННЫХ ИТ-КАДРОВ</strong></p>",
                "time": "13:05 - 13:25",
                "content": "",
                "order": 5,
                "createdAt": "2022-04-06T20:36:00.000Z",
                "updatedAt": "2022-04-18T21:05:19.000Z",
                "section_id": 5,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 5,
                    "name": "Секция 4",
                    "theme": "Система воспроизводства ИТ-кадров",
                    "order": 5,
                    "createdAt": "2022-03-14T12:00:01.000Z",
                    "updatedAt": "2022-04-14T12:59:18.000Z"
                },
                "Speaker": [{
                    "id": 76,
                    "name": "Малиновская Анна",
                    "job": "Руководитель представительства в Центральном федеральном округе Университета Иннополис",
                    "avatar": "/images/Иннопоплис_Малиновская А.С..jpeg",
                    "info": "<p>Окончила специальность «Автоматизированные системы обработки информации и управления» в Нижегородском государственном техническом университете им. Р.Е. Алексеева, более 10 лет работает в системе высшего образования.</p>",
                    "createdAt": "2022-04-18T20:31:46.000Z",
                    "updatedAt": "2022-04-18T20:31:46.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-18T21:05:19.000Z",
                        "updatedAt": "2022-04-18T21:05:19.000Z",
                        "program_id": 43,
                        "speaker_id": 76
                    }
                }]
            }, {
                "id": 24,
                "name": "<p><strong>IVA UC – РОССИЙСКАЯ ЭКОСИСТЕМА УНИФИЦИРОВАННЫХ КОРПОРАТИВНЫХ КОММУНИКАЦИЙ</strong></p>",
                "time": "11:05 - 11:20",
                "content": "<p> </p>",
                "order": 6,
                "createdAt": "2022-04-02T11:20:46.000Z",
                "updatedAt": "2022-04-13T06:28:21.000Z",
                "section_id": 1,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 1,
                    "name": "Пленарная сессия",
                    "theme": "На пути к глобальной ИТ-трансформации",
                    "order": 1,
                    "createdAt": "2022-03-14T11:55:29.000Z",
                    "updatedAt": "2022-03-14T11:55:29.000Z"
                },
                "Speaker": [{
                    "id": 56,
                    "name": "Иодковский Станислав",
                    "job": "Генеральный директор компании IVA Technologies, ГК «ХайТэк»",
                    "avatar": "/images/V_IVA_iodkovsky.jpg",
                    "info": "<p>В 2011 году окончил РГСУ по специальности «Финансы и кредит». Карьеру в ИТ начал в Корпорации «ЮНИ», где отвечал за работу с госсектором, занимался развитием бизнеса в САТЕЛ и OCS. В 2013 году совместно с компаньонами основал компанию «ХайТэк», позднее ставшую группой компаний.</p>",
                    "createdAt": "2022-04-13T06:18:12.000Z",
                    "updatedAt": "2022-04-13T06:18:12.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-13T06:28:21.000Z",
                        "updatedAt": "2022-04-13T06:28:21.000Z",
                        "program_id": 24,
                        "speaker_id": 56
                    }
                }]
            }, {
                "id": 3,
                "name": "<p><strong>ПРАКТИКА ЦИФРОВОЙ ТРАНСФОРМАЦИИ SOFTLINE С ИСПОЛЬЗОВАНИЕМ РЕШЕНИЙ ASTRA LINUX</strong></p>",
                "time": "13:25 - 13:45",
                "content": "<h3><br></h3>",
                "order": 6,
                "createdAt": "2022-03-14T12:02:19.000Z",
                "updatedAt": "2022-04-19T21:32:04.000Z",
                "section_id": 2,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 2,
                    "name": "Секция 1",
                    "theme": "Практика импортозамещения",
                    "order": 2,
                    "createdAt": "2022-03-14T11:58:59.000Z",
                    "updatedAt": "2022-04-02T18:52:17.000Z"
                },
                "Speaker": [{
                    "id": 31,
                    "name": "Кузнецов Павел",
                    "job": "Менеджер по развитию бизнеса ГК «Астра», компания Softline",
                    "avatar": "/images/V_Софтлайн_Кузнецов-Павел.jpg",
                    "info": "<p>Закончил Московский институт инженеров транспорта, факультет Технической кибернетики. В ИТ-секторе работает более 18 лет, в компании Softline занимается развитием решений Astra Linux.</p>",
                    "createdAt": "2022-04-03T09:40:12.000Z",
                    "updatedAt": "2022-04-13T18:42:44.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-04T06:15:15.000Z",
                        "updatedAt": "2022-04-04T06:15:15.000Z",
                        "program_id": 3,
                        "speaker_id": 31
                    }
                }, {
                    "id": 57,
                    "name": "Орфаниди Денис",
                    "job": "руководитель направления экспертизы по импортозамещению компании Softline",
                    "avatar": "/images/V_Softline_Orfanidi.jpg",
                    "info": "<p>В ИТ-сфере 20 лет. Опыт включает создание, модернизацию и поддержку сложных информационных и коммуникационных систем крупных организаций; продвижение и поддержку продаж оборудования, системную интеграцию в сфере ИТ.</p>",
                    "createdAt": "2022-04-13T06:19:07.000Z",
                    "updatedAt": "2022-04-13T06:19:07.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-13T06:29:13.000Z",
                        "updatedAt": "2022-04-13T06:29:13.000Z",
                        "program_id": 3,
                        "speaker_id": 57
                    }
                }]
            }, {
                "id": 40,
                "name": "<p><strong>ALD PRO – ПРОСТОЕ УПРАВЛЕНИЕ СЛУЖБОЙ КАТАЛОГОВ</strong></p>",
                "time": "13:25 - 13:45",
                "content": "",
                "order": 6,
                "createdAt": "2022-04-06T18:06:32.000Z",
                "updatedAt": "2022-04-18T20:52:20.000Z",
                "section_id": 3,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 3,
                    "name": "Секция 2",
                    "theme": "ИТ-решения для цифровизации",
                    "order": 3,
                    "createdAt": "2022-03-14T11:59:20.000Z",
                    "updatedAt": "2022-04-02T18:52:18.000Z"
                },
                "Speaker": [{
                    "id": 41,
                    "name": "Фоменко Алексей",
                    "job": "Заместитель директора департамента разработки ГК «Астра»",
                    "avatar": "/images/V_ASTRA_Fomenko.jpg",
                    "info": "<p>15-летний опыт разработки в ведущих российских ИТ-компаниях. Реализовал в масштабах страны федеральный проект в инфраструктуре электронного правительства. Запустил проекты «Мультирегиональность ЕПГУ» и «Популяризация государственных услуг». На проектах по информатизации Электронного правительства изменил подход с проектного на продуктовый, созданы дополнительные продукты для регионов, предоставляемые как SaaS. Платформа «Цифровое правительство субъекта РФ» оценена сотрудниками АНО «Цифровая экономика» и размещена в базе эффективных кейсов для развития цифровой экономики в субъектах РФ.</p>",
                    "createdAt": "2022-04-06T21:34:15.000Z",
                    "updatedAt": "2022-04-08T21:50:38.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-07T08:34:50.000Z",
                        "updatedAt": "2022-04-07T08:34:50.000Z",
                        "program_id": 40,
                        "speaker_id": 41
                    }
                }]
            }, {
                "id": 49,
                "name": "<p><strong>ALD PRO – РЕШЕНИЕ ДЛЯ ЦЕНТРАЛИЗОВАННОГО УПРАВЛЕНИЯ И АВТОМАТИЗАЦИИ</strong></p>",
                "time": "14:00 - 14:35",
                "content": "",
                "order": 6,
                "createdAt": "2022-04-06T20:48:23.000Z",
                "updatedAt": "2022-04-08T21:54:58.000Z",
                "section_id": 4,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 4,
                    "name": "Секция 3",
                    "theme": "Как это работает: демонстрация решений",
                    "order": 4,
                    "createdAt": "2022-03-14T11:59:41.000Z",
                    "updatedAt": "2022-04-02T18:52:19.000Z"
                },
                "Speaker": [{
                    "id": 43,
                    "name": "Паутов Евгений",
                    "job": "Менеджер продукта ALD Pro, ГК «Астра»",
                    "avatar": "/images/V_ASTRA_Pautov.jpg",
                    "info": "<p>С 2012 по 2020 гг. работал в компаниях AT Consulting и РТ Лабс на проектах по разработке и внедрению ведомственных информационных систем для оказания государственных услуг. С 2020 г. работает в ГК «Астра» на позиции менеджера продукта ALD Pro.</p>",
                    "createdAt": "2022-04-06T21:35:48.000Z",
                    "updatedAt": "2022-04-08T21:50:53.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-07T07:27:40.000Z",
                        "updatedAt": "2022-04-07T07:27:40.000Z",
                        "program_id": 49,
                        "speaker_id": 43
                    }
                }]
            }, {
                "id": 70,
                "name": "<p><strong>ВЫСШЕЕ ОБРАЗОВАНИЕ С ОПОРОЙ НА НАЦИОНАЛЬНЫЕ ПЛАТФОРМЫ. ИСПОЛЬЗОВАНИЕ РЕШЕНИЙ ASTRA LINUX ПРИ ПРОВЕДЕНИИ СОРЕВНОВАНИЙ WORLDSKILLS RUSSIA И В ПРОЕКТНОЙ МОДЕЛИ ОБУЧЕНИЯ МИЭМ НИУ ВШЭ.</strong></p>",
                "time": "13:25 - 13:45",
                "content": "",
                "order": 6,
                "createdAt": "2022-04-13T06:44:30.000Z",
                "updatedAt": "2022-04-13T06:44:30.000Z",
                "section_id": 5,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 5,
                    "name": "Секция 4",
                    "theme": "Система воспроизводства ИТ-кадров",
                    "order": 5,
                    "createdAt": "2022-03-14T12:00:01.000Z",
                    "updatedAt": "2022-04-14T12:59:18.000Z"
                },
                "Speaker": [{
                    "id": 60,
                    "name": "Сергеев Антон",
                    "job": "Советник ректора Московского института электроники и математики им. А.Н. Тихонова НИУ ВШЭ",
                    "avatar": "/images/V_MIEM_Sergeev.jpg",
                    "info": "",
                    "createdAt": "2022-04-13T06:43:43.000Z",
                    "updatedAt": "2022-04-13T06:43:43.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-13T06:44:30.000Z",
                        "updatedAt": "2022-04-13T06:44:30.000Z",
                        "program_id": 70,
                        "speaker_id": 60
                    }
                }]
            }, {
                "id": 25,
                "name": "<p><strong>ЗАЩИЩЕННАЯ ОС ASTRA LINUX: КАК ОБЕСПЕЧИВАЮТСЯ ДОВЕРИЕ И БЕЗОПАСНОСТЬ</strong></p>",
                "time": "11:20 - 11:35",
                "content": "<p> </p>",
                "order": 7,
                "createdAt": "2022-04-02T11:46:21.000Z",
                "updatedAt": "2022-04-20T13:32:22.000Z",
                "section_id": 1,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 1,
                    "name": "Пленарная сессия",
                    "theme": "На пути к глобальной ИТ-трансформации",
                    "order": 1,
                    "createdAt": "2022-03-14T11:55:29.000Z",
                    "updatedAt": "2022-03-14T11:55:29.000Z"
                },
                "Speaker": [{
                    "id": 28,
                    "name": "Девянин Петр",
                    "job": "Научный руководитель ГК «Астра»",
                    "avatar": "/images/V_Астра_Девянин-Петр.jpg",
                    "info": "<p>Член-корреспондент Академии криптографии Российской Федерации, доктор технических наук, профессор, автор около 150 научных, учебно-методических публикаций и патентов на изобретения. Специалист по теории компьютерной безопасности, по разработке формальных моделей безопасности управления доступом и информационными потоками. На протяжении нескольких лет занимается научным сопровождением разработки ОС Astra Linux. С 2018 г. осуществляет преподавательскую деятельность в МТУ (МИРЭА).</p>",
                    "createdAt": "2022-04-03T09:20:13.000Z",
                    "updatedAt": "2022-04-04T06:09:02.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-04T06:13:09.000Z",
                        "updatedAt": "2022-04-04T06:13:09.000Z",
                        "program_id": 25,
                        "speaker_id": 28
                    }
                }]
            }, {
                "id": 12,
                "name": "<p><strong>ПЕРЕРЫВ</strong></p>",
                "time": "13:45 - 14:00",
                "content": "<p> </p>",
                "order": 7,
                "createdAt": "2022-03-14T12:08:31.000Z",
                "updatedAt": "2022-04-04T12:25:34.000Z",
                "section_id": 2,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 2,
                    "name": "Секция 1",
                    "theme": "Практика импортозамещения",
                    "order": 2,
                    "createdAt": "2022-03-14T11:58:59.000Z",
                    "updatedAt": "2022-04-02T18:52:17.000Z"
                },
                "Speaker": []
            }, {
                "id": 57,
                "name": "<p><strong>ИМПОРТОЗАМЕЩЕНИЕ ШАГ ЗА ШАГОМ</strong></p>",
                "time": "14:00 - 14:20",
                "content": "",
                "order": 7,
                "createdAt": "2022-04-06T21:14:32.000Z",
                "updatedAt": "2022-04-19T21:29:15.000Z",
                "section_id": 2,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 2,
                    "name": "Секция 1",
                    "theme": "Практика импортозамещения",
                    "order": 2,
                    "createdAt": "2022-03-14T11:58:59.000Z",
                    "updatedAt": "2022-04-02T18:52:17.000Z"
                },
                "Speaker": [{
                    "id": 46,
                    "name": "Дарчинов Станислав",
                    "job": "Технический директор STEP LOGIC",
                    "avatar": "/images/V_STEP_LOGIC_Darchinov.jpg",
                    "info": "<p>Окончил КГТУ им. А.Н. Туполева по специальности «Комплексная защита объектов информатизации». Карьеру в STEP LOGIC начал в 2009 году с позиции младшего сетевого инженера, затем руководил группой сетевых решений, являлся техническим, а затем исполнительным директором казанского филиала компании. Под его руководством реализованы проекты по оснащению Зимней Универсиады 2019, построена ИТ- инфраструктура завода Haier, проведена модернизация мультисервисной сети передачи данных ПАО «Таттелеком». В 2020 году назначен на должность технического директора STEP LOGIC.</p>",
                    "createdAt": "2022-04-08T06:28:46.000Z",
                    "updatedAt": "2022-04-08T06:28:46.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-08T06:35:19.000Z",
                        "updatedAt": "2022-04-08T06:35:19.000Z",
                        "program_id": 57,
                        "speaker_id": 46
                    }
                }]
            }, {
                "id": 14,
                "name": "<p><strong>ПЕРЕРЫВ</strong></p>",
                "time": "13:45 - 14:00",
                "content": "<p> </p>",
                "order": 7,
                "createdAt": "2022-03-14T12:09:19.000Z",
                "updatedAt": "2022-04-04T12:25:40.000Z",
                "section_id": 3,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 3,
                    "name": "Секция 2",
                    "theme": "ИТ-решения для цифровизации",
                    "order": 3,
                    "createdAt": "2022-03-14T11:59:20.000Z",
                    "updatedAt": "2022-04-02T18:52:18.000Z"
                },
                "Speaker": []
            }, {
                "id": 50,
                "name": "<p><strong>ТЕХНОЛОГИЯ РАЗРАБОТКИ ПРИКЛАДНОГО ПО В ЗАЩИЩЕННОЙ СРЕДЕ ОС ASTRA LINUX</strong></p>",
                "time": "14:35 - 15:10",
                "content": "",
                "order": 7,
                "createdAt": "2022-04-06T20:50:28.000Z",
                "updatedAt": "2022-04-13T06:39:24.000Z",
                "section_id": 4,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 4,
                    "name": "Секция 3",
                    "theme": "Как это работает: демонстрация решений",
                    "order": 4,
                    "createdAt": "2022-03-14T11:59:41.000Z",
                    "updatedAt": "2022-04-02T18:52:19.000Z"
                },
                "Speaker": [{
                    "id": 36,
                    "name": "Борисов Андрей",
                    "job": "Ведущий инженер-исследователь ГК «Астра»",
                    "avatar": "/images/V_Астра_Борисов-Андрей.jpg",
                    "info": "<p>30 лет занимается разработкой и внедрением информационных систем специального назначения, из них более 20 лет на Linux-платформах ОС МСВС и ОС Astra Linux Special Edition.</p>",
                    "createdAt": "2022-04-03T09:47:09.000Z",
                    "updatedAt": "2022-04-04T21:02:55.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-07T07:28:04.000Z",
                        "updatedAt": "2022-04-07T07:28:04.000Z",
                        "program_id": 50,
                        "speaker_id": 36
                    }
                }]
            }, {
                "id": 21,
                "name": "<p><strong>ПЕРЕРЫВ</strong></p>",
                "time": "13:45 - 14:00",
                "content": "<p> </p>",
                "order": 7,
                "createdAt": "2022-03-14T12:12:22.000Z",
                "updatedAt": "2022-04-04T12:25:52.000Z",
                "section_id": 5,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 5,
                    "name": "Секция 4",
                    "theme": "Система воспроизводства ИТ-кадров",
                    "order": 5,
                    "createdAt": "2022-03-14T12:00:01.000Z",
                    "updatedAt": "2022-04-14T12:59:18.000Z"
                },
                "Speaker": []
            }, {
                "id": 26,
                "name": "<p><strong>ПЕРЕРЫВ, ОСМОТР ВЫСТАВОЧНОЙ ЗОНЫ</strong></p>",
                "time": "11:35 - 12:00",
                "content": "<p> </p>",
                "order": 8,
                "createdAt": "2022-04-02T11:47:06.000Z",
                "updatedAt": "2022-04-15T20:55:43.000Z",
                "section_id": 1,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 1,
                    "name": "Пленарная сессия",
                    "theme": "На пути к глобальной ИТ-трансформации",
                    "order": 1,
                    "createdAt": "2022-03-14T11:55:29.000Z",
                    "updatedAt": "2022-03-14T11:55:29.000Z"
                },
                "Speaker": []
            }, {
                "id": 60,
                "name": "<p><strong>НОВОЕ ПОКОЛЕНИЕ КОРПОРАТИВНОЙ ПОЧТЫ И БЕЗОПАСНОЙ МОБИЛЬНОСТИ: RUPOST И WORKSPAD</strong></p>",
                "time": "14:00 - 14:20",
                "content": "",
                "order": 8,
                "createdAt": "2022-04-09T15:24:00.000Z",
                "updatedAt": "2022-04-18T20:55:22.000Z",
                "section_id": 3,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 3,
                    "name": "Секция 2",
                    "theme": "ИТ-решения для цифровизации",
                    "order": 3,
                    "createdAt": "2022-03-14T11:59:20.000Z",
                    "updatedAt": "2022-04-02T18:52:18.000Z"
                },
                "Speaker": [{
                    "id": 50,
                    "name": "Макарьин Сергей",
                    "job": "Директор департамента WorksPad ГК «Астра»",
                    "avatar": "/images/V_ASTRA_Makaryin.jpg",
                    "info": "",
                    "createdAt": "2022-04-09T15:38:49.000Z",
                    "updatedAt": "2022-04-13T21:35:25.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-09T15:46:47.000Z",
                        "updatedAt": "2022-04-09T15:46:47.000Z",
                        "program_id": 60,
                        "speaker_id": 50
                    }
                }]
            }, {
                "id": 64,
                "name": "<p><strong>RUPOST И WORKSPAD: КОРПОРАТИВНАЯ ПОЧТА И БЕЗОПАСНАЯ МОБИЛЬНОСТЬ</strong></p>",
                "time": "15:10 - 15:45",
                "content": "",
                "order": 8,
                "createdAt": "2022-04-09T15:52:15.000Z",
                "updatedAt": "2022-04-13T06:40:03.000Z",
                "section_id": 4,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 4,
                    "name": "Секция 3",
                    "theme": "Как это работает: демонстрация решений",
                    "order": 4,
                    "createdAt": "2022-03-14T11:59:41.000Z",
                    "updatedAt": "2022-04-02T18:52:19.000Z"
                },
                "Speaker": [{
                    "id": 48,
                    "name": "Орлик Сергей",
                    "job": "Директор по продуктам и технологиям «Рупост»",
                    "avatar": "/images/V_ASTRA_Orlik.jpg",
                    "info": "",
                    "createdAt": "2022-04-09T15:20:30.000Z",
                    "updatedAt": "2022-04-13T21:34:57.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-09T15:52:15.000Z",
                        "updatedAt": "2022-04-09T15:52:15.000Z",
                        "program_id": 64,
                        "speaker_id": 48
                    }
                }]
            }, {
                "id": 77,
                "name": "<p><strong>ASTRA LINUX В ОБРАЗОВАТЕЛЬНОМ И АДМИНИСТРАТИВНОМ ПРОЦЕССАХ УЧЕБНОГО ЗАВЕДЕНИЯ</strong></p>",
                "time": "14:00 - 14:20",
                "content": "",
                "order": 8,
                "createdAt": "2022-04-18T21:07:41.000Z",
                "updatedAt": "2022-04-20T13:34:50.000Z",
                "section_id": 5,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 5,
                    "name": "Секция 4",
                    "theme": "Система воспроизводства ИТ-кадров",
                    "order": 5,
                    "createdAt": "2022-03-14T12:00:01.000Z",
                    "updatedAt": "2022-04-14T12:59:18.000Z"
                },
                "Speaker": [{
                    "id": 78,
                    "name": "Борисов Роман",
                    "job": "Заместитель коммерческого директора по работе с ключевыми заказчиками ГК «Астра»",
                    "avatar": "/images/Астра_Борисов Роман.jpg",
                    "info": "<p>Родился во Пскове. Получил 2 высших образования в Санкт-Петербурге и в Москве. На протяжении 15 лет занимался проектами в образовании. Прошел путь от образовательной организации до Минпросвещения России. Активный участник комитета по образованию АРПП \"Отечественный софт\".</p>",
                    "createdAt": "2022-04-18T20:39:21.000Z",
                    "updatedAt": "2022-04-20T16:02:28.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-18T21:07:41.000Z",
                        "updatedAt": "2022-04-18T21:07:41.000Z",
                        "program_id": 77,
                        "speaker_id": 78
                    }
                }]
            }, {
                "id": 67,
                "name": "<p><strong>МЕТОДОЛОГИЯ МОЙОФИС ДЛЯ ПЕРЕХОДА С ИНОСТРАННЫХ ОФИСНЫХ РЕШЕНИЙ</strong></p>",
                "time": "14:20 - 14:40",
                "content": "",
                "order": 9,
                "createdAt": "2022-04-13T06:31:40.000Z",
                "updatedAt": "2022-04-20T13:30:34.000Z",
                "section_id": 2,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 2,
                    "name": "Секция 1",
                    "theme": "Практика импортозамещения",
                    "order": 2,
                    "createdAt": "2022-03-14T11:58:59.000Z",
                    "updatedAt": "2022-04-02T18:52:17.000Z"
                },
                "Speaker": [{
                    "id": 68,
                    "name": "Бигулов Тимур",
                    "job": "Руководитель отдела по работе с партнерами «МойОфис»",
                    "avatar": "/images/V_MOIOFFICE_Bigulov.jpg",
                    "info": "<p>Ранее работал в топ-5 банках – «Альфа-Банк», «Райффазенбанк», ВТБ. Отвечал за формирование клиентской стратегии «Альфа-Банка» и продуктовой стратегии ВТБ. После этого проработал 5 лет в «Лаборатории Касперского», где отвечал за партнерские продажи клиентам малого и среднего бизнеса. Сейчас занимает позицию руководителя направления по работе с партнерами.</p>",
                    "createdAt": "2022-04-15T06:25:52.000Z",
                    "updatedAt": "2022-04-20T16:00:03.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-15T06:43:01.000Z",
                        "updatedAt": "2022-04-15T06:43:01.000Z",
                        "program_id": 67,
                        "speaker_id": 68
                    }
                }]
            }, {
                "id": 53,
                "name": "<p><strong>RUBACKUP 1.9: НОВОЕ В СИСТЕМЕ РЕЗЕРВНОГО КОПИРОВАНИЯ</strong></p>",
                "time": "14:20 - 14:40",
                "content": "",
                "order": 9,
                "createdAt": "2022-04-06T20:57:53.000Z",
                "updatedAt": "2022-04-18T20:55:59.000Z",
                "section_id": 3,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 3,
                    "name": "Секция 2",
                    "theme": "ИТ-решения для цифровизации",
                    "order": 3,
                    "createdAt": "2022-03-14T11:59:20.000Z",
                    "updatedAt": "2022-04-02T18:52:18.000Z"
                },
                "Speaker": [{
                    "id": 39,
                    "name": "Кузнецов Андрей",
                    "job": "Генеральный директор компании RuBackup",
                    "avatar": "/images/RuBackup_Кузнецов Андрей.jpg",
                    "info": "<p>В области ИКТ более 15 лет. В период с 2004 по 2016 гг. занимал руководящие посты в ИТ-компаниях «Открытые Технологии», «Оптима», «Компьюлинк», Inspur, «Булат».&nbsp;</p><p>С 2018 г. занимается развитием системы резервного копирования RuBackup и является генеральным директором ООО «Рубэкап».</p>",
                    "createdAt": "2022-04-06T21:28:54.000Z",
                    "updatedAt": "2022-04-06T21:28:54.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-07T07:32:33.000Z",
                        "updatedAt": "2022-04-07T07:32:33.000Z",
                        "program_id": 53,
                        "speaker_id": 39
                    }
                }]
            }, {
                "id": 78,
                "name": "<p><strong>ВЫЗОВЫ СОВРЕМЕННОГО ОБРАЗОВАТЕЛЬНОГО РЫНКА: ЗАПРОС РЫНКА И РЕАЛИИ. ПРАКТИЧЕСКИЙ КЕЙС \"ТРАЕКТОРИЯ БУДУЩЕГО\" И ПЕРСПЕКТИВЫ РАЗВИТИЯ</strong></p>",
                "time": "14:20 - 14:40",
                "content": "",
                "order": 9,
                "createdAt": "2022-04-18T21:08:54.000Z",
                "updatedAt": "2022-04-19T21:35:38.000Z",
                "section_id": 5,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 5,
                    "name": "Секция 4",
                    "theme": "Система воспроизводства ИТ-кадров",
                    "order": 5,
                    "createdAt": "2022-03-14T12:00:01.000Z",
                    "updatedAt": "2022-04-14T12:59:18.000Z"
                },
                "Speaker": [{
                    "id": 77,
                    "name": "Морохова Светлана",
                    "job": "Исполнительный директор группы компаний IThub, директор Ассоциации специалистов по сертификации",
                    "avatar": "/images/ITHub_Морохова Светлана.jpg",
                    "info": "<p>Высшее техническое образование, высшее педагогическое образование. 23 года опыта в образовании на позиции управленца высшего звена. Опыт работы на руководящих позициях в крупных российских компаниях «Лукойл-Гарант», НПФ «Лукойл-Гарант», НОУ ДПО «Институт АйТи» и международных компаниях -Lungrui, YouNeed. Сертифицированный бизнес-тренер, коуч в направлении «Коучинг в управлении» (Эриксоновский международный университет), член экспертного совета Агентства стратегических инициатив.</p>",
                    "createdAt": "2022-04-18T20:36:07.000Z",
                    "updatedAt": "2022-04-18T20:36:07.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-18T21:08:54.000Z",
                        "updatedAt": "2022-04-18T21:08:54.000Z",
                        "program_id": 78,
                        "speaker_id": 77
                    }
                }]
            }, {
                "id": 62,
                "name": "<p><strong>ОПЫТ ВНЕДРЕНИЯ ОС ASTRA LINUX КАК ЭЛЕМЕНТА АТТЕСТОВАННОГО КОНТУРА К1 ГОСУДАРСТВЕННОГО РЕГИОНАЛЬНОГО ЦОДА НИЖЕГОРОДСКОЙ ОБЛАСТИ</strong></p>",
                "time": "14:40 - 15:00",
                "content": "",
                "order": 10,
                "createdAt": "2022-04-09T15:44:49.000Z",
                "updatedAt": "2022-04-15T20:48:04.000Z",
                "section_id": 2,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 2,
                    "name": "Секция 1",
                    "theme": "Практика импортозамещения",
                    "order": 2,
                    "createdAt": "2022-03-14T11:58:59.000Z",
                    "updatedAt": "2022-04-02T18:52:17.000Z"
                },
                "Speaker": [{
                    "id": 52,
                    "name": "Служеникин Дмитрий",
                    "job": "Начальник отдела по защите информации Центра информационных технологий Нижегородской области",
                    "avatar": "/images/V_CIT_NNOVGORODA_Sluzhenikin.jpg",
                    "info": "<p>Профессиональную деятельность начал в 2004г. Работал в операторах связи различных стандартов от CDMA450 и dsl до оптический сетей в рамках ТТК и «Ротелекома» </p><p>Успешный опыт реализации проектов по созданию серверной и телекоммуникационной инфраструктуры для УАЗ, АЭТП, «Связьтранснефть». С 2012г. активно занимается вопросами информационной безопасности в том числе в государственных организациях. В 2020г. участвовал в создании Регионального Центра обработки данных правительства Нижегородской области как руководитель группы по защите информации ГАУ НО «ЦИТ», в чьем ведении находится Региональный ЦОД, отвечал за создание и аттестацию сегментов К1 и К3.</p>",
                    "createdAt": "2022-04-09T15:40:15.000Z",
                    "updatedAt": "2022-04-13T06:21:22.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-09T15:44:49.000Z",
                        "updatedAt": "2022-04-09T15:44:49.000Z",
                        "program_id": 62,
                        "speaker_id": 52
                    }
                }]
            }, {
                "id": 54,
                "name": "<p><strong> ЭФФЕКТИВНЫЕ ИНСТРУМЕНТЫ ЗАЩИТЫ БИЗНЕСА</strong></p>",
                "time": "14:40 - 15:00",
                "content": "",
                "order": 10,
                "createdAt": "2022-04-06T21:04:33.000Z",
                "updatedAt": "2022-04-18T20:56:37.000Z",
                "section_id": 3,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 3,
                    "name": "Секция 2",
                    "theme": "ИТ-решения для цифровизации",
                    "order": 3,
                    "createdAt": "2022-03-14T11:59:20.000Z",
                    "updatedAt": "2022-04-02T18:52:18.000Z"
                },
                "Speaker": [{
                    "id": 47,
                    "name": "Матюшенок Евгений",
                    "job": "Директор по продажам SearchInform в РФ и СНГ ",
                    "avatar": "/images/V_Searchinform_Matushenok.jpg",
                    "info": "",
                    "createdAt": "2022-04-08T06:38:21.000Z",
                    "updatedAt": "2022-04-08T06:38:21.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-08T06:38:44.000Z",
                        "updatedAt": "2022-04-08T06:38:44.000Z",
                        "program_id": 54,
                        "speaker_id": 47
                    }
                }]
            }, {
                "id": 42,
                "name": "<p><strong>ОБУЧЕНИЕ ЭФФЕКТИВНОМУ ИСПОЛЬЗОВАНИЮ МОДУЛЕЙ РАСШИРЕНИЯ POSTGRESQL 11 В ASTRA LINUX SPECIAL EDITION</strong></p>",
                "time": "14:40 - 15:00",
                "content": "",
                "order": 10,
                "createdAt": "2022-04-06T20:34:57.000Z",
                "updatedAt": "2022-04-19T21:36:08.000Z",
                "section_id": 5,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 5,
                    "name": "Секция 4",
                    "theme": "Система воспроизводства ИТ-кадров",
                    "order": 5,
                    "createdAt": "2022-03-14T12:00:01.000Z",
                    "updatedAt": "2022-04-14T12:59:18.000Z"
                },
                "Speaker": [{
                    "id": 35,
                    "name": "Береснев Алексей ",
                    "job": "Ведущий преподаватель УКЦ «ФОРС»",
                    "avatar": "/images/V_ФОРС_Береснев-Алексей.jpg",
                    "info": "<p>Автор книги \"Администрирование GNU/Linux с нуля\", автор сертифицированных программ повышения квалификации и авторских курсов УКЦ «ФОРС». Имеет многолетний опыт проведения учебных курсов по CISCO, Oracle (Sun) Solaris, SCO OpenServer и UnixWare, Oracle RDBMS, PostgreSQL, Python, Astra Linux Special Edition</p>",
                    "createdAt": "2022-04-03T09:46:30.000Z",
                    "updatedAt": "2022-04-04T21:02:47.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-06T20:34:57.000Z",
                        "updatedAt": "2022-04-06T20:34:57.000Z",
                        "program_id": 42,
                        "speaker_id": 35
                    }
                }]
            }, {
                "id": 59,
                "name": "<p><strong>НОВЫЕ РЕАЛИИ: РОССИЙСКИЕ ИТ-РЕШЕНИЯ В МФЦ</strong></p>",
                "time": "15:00 - 15:20",
                "content": "",
                "order": 11,
                "createdAt": "2022-04-06T21:16:42.000Z",
                "updatedAt": "2022-04-09T15:43:39.000Z",
                "section_id": 2,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 2,
                    "name": "Секция 1",
                    "theme": "Практика импортозамещения",
                    "order": 2,
                    "createdAt": "2022-03-14T11:58:59.000Z",
                    "updatedAt": "2022-04-02T18:52:17.000Z"
                },
                "Speaker": [{
                    "id": 45,
                    "name": "Кабанов Денис",
                    "job": "Заместитель директора МКУ «МФЦ в г. Ставрополе»",
                    "avatar": "/images/V_MFC_Kabanov.jpg",
                    "info": "<p>Окончил в 1999г. Владикавказский колледж электроники по специальности «Программист». В 2004г. окончил Владикавказский Горнометаллургический Институт (ГТУ) с присвоением квалификации инженера по специальности «Автоматизация технологических процессов и производств». С 2000г. по 2018г. работал в ОАО «РЖД» инженером-программистом, с июня 2018г. - в МКУ «МФЦ в г. Ставрополе».</p>",
                    "createdAt": "2022-04-07T07:20:12.000Z",
                    "updatedAt": "2022-04-08T06:30:07.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-08T06:36:28.000Z",
                        "updatedAt": "2022-04-08T06:36:28.000Z",
                        "program_id": 59,
                        "speaker_id": 45
                    }
                }]
            }, {
                "id": 55,
                "name": "<p><strong>РОССИЙСКИЕ РЕШЕНИЯ КОМПАНИИ DEPO COMPUTERS ДЛЯ ИМПОРТОЗАМЕЩЕНИЯ</strong></p>",
                "time": "15:00 - 15:20",
                "content": "",
                "order": 11,
                "createdAt": "2022-04-06T21:05:25.000Z",
                "updatedAt": "2022-04-18T21:00:34.000Z",
                "section_id": 3,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 3,
                    "name": "Секция 2",
                    "theme": "ИТ-решения для цифровизации",
                    "order": 3,
                    "createdAt": "2022-03-14T11:59:20.000Z",
                    "updatedAt": "2022-04-02T18:52:18.000Z"
                },
                "Speaker": [{
                    "id": 79,
                    "name": "Сенько Сергей",
                    "job": "Начальник отдела серверной продукции DEPO Computers",
                    "avatar": "/images/DEPO_Сенько Сергей.jpg",
                    "info": "<p>Закончил МГУ в 1993 году по специализации геолог-геофизик. Более 30 лет в ИТ-сфере. Карьеру в DEPO Computers начал с позиции менеджера по продукту в 2005 году. В данный момент занимает должность начальника отдела серверной продукции. Отвечает за формирование продуктового портфеля компании по серверным продуктам и реализацию бизнес-стратегий.</p>",
                    "createdAt": "2022-04-20T13:26:45.000Z",
                    "updatedAt": "2022-04-20T13:26:45.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-20T13:29:57.000Z",
                        "updatedAt": "2022-04-20T13:29:57.000Z",
                        "program_id": 55,
                        "speaker_id": 79
                    }
                }]
            }, {
                "id": 34,
                "name": "<p><strong>ASTRA LINUX. РУКОВОДСТВО&nbsp;ПО НАЦИОНАЛЬНОЙ ОПЕРАЦИОННОЙ&nbsp;СИСТЕМЕ И СОВМЕСТИМЫМ ОФИСНЫМ ПРОГРАММАМ</strong></p>",
                "time": "15:00 - 15:20",
                "content": "<p> </p>",
                "order": 11,
                "createdAt": "2022-04-02T12:19:55.000Z",
                "updatedAt": "2022-04-19T21:36:46.000Z",
                "section_id": 5,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 5,
                    "name": "Секция 4",
                    "theme": "Система воспроизводства ИТ-кадров",
                    "order": 5,
                    "createdAt": "2022-03-14T12:00:01.000Z",
                    "updatedAt": "2022-04-14T12:59:18.000Z"
                },
                "Speaker": [{
                    "id": 71,
                    "name": "Вовк Елена",
                    "job": "Научный сотрудник лаборатории открытых информационных технологий, заместитель директора Учебного центра факультета ВМК МГУ им. М.В.Ломоносова",
                    "avatar": "/images/V_BMK_MGU_Vovk.jpg",
                    "info": "<p>Закончила факультет вычислительной математики и кибернетики (ВМК) МГУ им. М.В.Ломоносова, где в настоящий момент работает. Является заместителем директора Учебного центра факультета, ведет большую преподавательскую, методическую, просветительскую работу, являясь заместителем главного редактора образовательного журнала «Потенциал. Математика. Физика. Информатика».</p>",
                    "createdAt": "2022-04-15T06:34:57.000Z",
                    "updatedAt": "2022-04-15T06:34:57.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-15T06:48:44.000Z",
                        "updatedAt": "2022-04-15T06:48:44.000Z",
                        "program_id": 34,
                        "speaker_id": 71
                    }
                }]
            }, {
                "id": 74,
                "name": "<p><strong>ОПЫТ ИМПОРТОЗАМЕЩЕНИЯ В КРИТИЧЕСКИ ВАЖНОЙ ИТ-ИНФРАСТРУКТУРЕ БГМУ</strong></p>",
                "time": "15:20 - 15:40 ",
                "content": "",
                "order": 12,
                "createdAt": "2022-04-15T20:50:06.000Z",
                "updatedAt": "2022-04-18T20:41:08.000Z",
                "section_id": 2,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 2,
                    "name": "Секция 1",
                    "theme": "Практика импортозамещения",
                    "order": 2,
                    "createdAt": "2022-03-14T11:58:59.000Z",
                    "updatedAt": "2022-04-02T18:52:17.000Z"
                },
                "Speaker": [{
                    "id": 74,
                    "name": "Билялов Азат",
                    "job": "Начальник управления информационных технологий, доцент кафедры травматологии и ортопедии с курсом ИДПО Башкирского государственного медицинского университета",
                    "avatar": "/images/БГМУ_Билялов Азат.jpg",
                    "info": "<p>Окончил БГМУ в 2000 году с отличием. С 2000 года – сотрудник БГМУ, вначале – ординатор кафедры, затем, с 2007 года, – руководитель Центра дистанционного обучения БГМУ. В 2004 году защитил кандидатскую диссертацию по теме диагностики и лечения опухолей костей. С 2011 года – помощник проректора по учебной работе, с 2013 года – начальник созданного управления информационных технологий. В 2018 году окончил очную магистратуру по специальности «Прикладная математика и информатика» УГАТУ. Автор более 50 научных статей в российских и зарубежных изданиях, 5 патентов.</p>",
                    "createdAt": "2022-04-18T20:23:56.000Z",
                    "updatedAt": "2022-04-18T20:23:56.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-18T20:41:08.000Z",
                        "updatedAt": "2022-04-18T20:41:08.000Z",
                        "program_id": 74,
                        "speaker_id": 74
                    }
                }]
            }, {
                "id": 56,
                "name": "<p><strong>ЗАЩИТА КАНАЛОВ СВЯЗИ И УДАЛЕННОГО ДОСТУПА В НАСТОЯЩЕЕ ВРЕМЯ</strong></p>",
                "time": "15:20 - 15:40 ",
                "content": "",
                "order": 12,
                "createdAt": "2022-04-06T21:06:31.000Z",
                "updatedAt": "2022-04-18T21:01:12.000Z",
                "section_id": 3,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 3,
                    "name": "Секция 2",
                    "theme": "ИТ-решения для цифровизации",
                    "order": 3,
                    "createdAt": "2022-03-14T11:59:20.000Z",
                    "updatedAt": "2022-04-02T18:52:18.000Z"
                },
                "Speaker": [{
                    "id": 58,
                    "name": "Шпаков Андрей",
                    "job": "Руководитель продуктового направления компании «С-Терра СиЭсПи»",
                    "avatar": "/images/V_C-Terra_Shpakov.jpg",
                    "info": "<p>В области информационной безопасности работает с 2011 года. Свой трудовой путь начал с инженера компании «С-Терра СиЭсПи», потом руководителем отдела тех.консалтинга, где занимался технической консультацией клиентов, сопровождением крупных проектов и ключевых заказчиков, участием в различных мероприятиях и подготовкой бизнес-требований для разработки. С марта 2022 года возглавляет продуктовое направлении компании. Автор публикаций в изданиях (BIS Journal, БИТ, Connect, Information Security) и вебинаров</p><p>Спикер на мероприятияй: AM-live, Код ИБ, Gis-Days и других.</p>",
                    "createdAt": "2022-04-13T06:22:34.000Z",
                    "updatedAt": "2022-04-13T21:36:26.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-13T06:33:15.000Z",
                        "updatedAt": "2022-04-13T06:33:15.000Z",
                        "program_id": 56,
                        "speaker_id": 58
                    }
                }]
            }, {
                "id": 66,
                "name": "<p><strong>АКТУАЛЬНЫЕ ПРОБЛЕМЫ ПОДГОТОВКИ ИТ-СПЕЦИАЛИСТОВ В СОВРЕМЕННЫХ УСЛОВИЯХ</strong></p>",
                "time": "15:20 - 15:40",
                "content": "",
                "order": 12,
                "createdAt": "2022-04-12T09:55:10.000Z",
                "updatedAt": "2022-04-19T21:37:45.000Z",
                "section_id": 5,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 5,
                    "name": "Секция 4",
                    "theme": "Система воспроизводства ИТ-кадров",
                    "order": 5,
                    "createdAt": "2022-03-14T12:00:01.000Z",
                    "updatedAt": "2022-04-14T12:59:18.000Z"
                },
                "Speaker": [{
                    "id": 55,
                    "name": "Иванов Андрей",
                    "job": "Заведующий кафедрой защиты информации Новосибирского государственного технического университета",
                    "avatar": "/images/V_NGTU_Ivanov.jpg",
                    "info": "<p>Кандидат технических наук, доцент. Стаж педагогической и практической деятельности в области информационной безопасности более 10 лет. Является директором НОЦ «Представительство центра компетенций НТИ «Технологии доверенного взаимодействия». Член Федерального-методического объединения в сфере высшего образования по УГСН 10.00.00 Информационная безопасность. Имеет более 50 научных и учебно-методических публикаций.</p>",
                    "createdAt": "2022-04-12T09:51:24.000Z",
                    "updatedAt": "2022-04-12T09:51:24.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-12T09:55:10.000Z",
                        "updatedAt": "2022-04-12T09:55:10.000Z",
                        "program_id": 66,
                        "speaker_id": 55
                    }
                }, {
                    "id": 54,
                    "name": "Карпова Надежда",
                    "job": "Руководитель   образовательных программ по   направлению     подготовки «Информационная   безопасность»   Самарского государственного технического университета",
                    "avatar": "/images/V_SGTU_Karpova (1).jpg",
                    "info": "<p>Кандидат технических наук, доцент. Является аккредитованным экспертом в области проведения государственной аккредитации образовательных учреждений и научных организаций по направлению «Информационная безопасность», а также экспертом по профессионально-общественной аккредитации образовательных программ. Автор более 120 опубликованных научных работ, ведет активную научно-исследовательскую деятельность, руководит студенческими научными проектами&nbsp;&nbsp;</p>",
                    "createdAt": "2022-04-12T09:50:22.000Z",
                    "updatedAt": "2022-04-13T18:07:31.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-19T21:37:45.000Z",
                        "updatedAt": "2022-04-19T21:37:45.000Z",
                        "program_id": 66,
                        "speaker_id": 54
                    }
                }]
            }, {
                "id": 13,
                "name": "<p><strong>ВОПРОСЫ И ОТВЕТЫ</strong></p>",
                "time": "15:40 - 16:00",
                "content": "<p> </p>",
                "order": 13,
                "createdAt": "2022-03-14T12:08:53.000Z",
                "updatedAt": "2022-04-04T12:27:12.000Z",
                "section_id": 2,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 2,
                    "name": "Секция 1",
                    "theme": "Практика импортозамещения",
                    "order": 2,
                    "createdAt": "2022-03-14T11:58:59.000Z",
                    "updatedAt": "2022-04-02T18:52:17.000Z"
                },
                "Speaker": []
            }, {
                "id": 63,
                "name": "<p><strong>РАЗВИТИЕ ПЛАТФОРМЫ POLYMATICA ДЛЯ КОМПЛЕКСНОГО РЕШЕНИЯ ЗАДАЧ АНАЛИТИКИ И ВИЗУАЛИЗАЦИИ ДАННЫХ: ОТ АНАЛИТИЧЕСКИХ ОТЧЁТОВ К АЛГОРИТМАМ МАШИННОГО ОБУЧЕНИЯ!</strong></p>",
                "time": "15:40 - 16:00",
                "content": "",
                "order": 13,
                "createdAt": "2022-04-09T15:48:56.000Z",
                "updatedAt": "2022-04-18T21:02:30.000Z",
                "section_id": 3,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 3,
                    "name": "Секция 2",
                    "theme": "ИТ-решения для цифровизации",
                    "order": 3,
                    "createdAt": "2022-03-14T11:59:20.000Z",
                    "updatedAt": "2022-04-02T18:52:18.000Z"
                },
                "Speaker": [{
                    "id": 62,
                    "name": "Бредихин Алексей",
                    "job": "Руководитель по работе с партнерами компания «Полиматика»",
                    "avatar": "/images/Polymatica_Бредихин Алексей.png",
                    "info": "<p>Опыт работы в ИТ-отрасли более 10 лет. Кандидат технических наук. Присоединился к компании «Полиматика» в 2019 году. До этого работал в банках из первой десятки и крупных интеграторах. В его портфолио внедрение платформы Polymatica для крупных клиентов: Московский Метрополитен, Ростелеком и ряда других. В настоящий момент занимается развитием партнёрского канала как наиболее эффективного способа дистрибуции Polymatica.</p>",
                    "createdAt": "2022-04-13T18:05:11.000Z",
                    "updatedAt": "2022-04-13T18:05:11.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-13T18:53:37.000Z",
                        "updatedAt": "2022-04-13T18:53:37.000Z",
                        "program_id": 63,
                        "speaker_id": 62
                    }
                }]
            }, {
                "id": 7,
                "name": "<p><strong>ВОПРОСЫ И ОТВЕТЫ</strong></p>",
                "time": "15:45 - 16:00",
                "content": "<h3><br></h3>",
                "order": 13,
                "createdAt": "2022-03-14T12:05:05.000Z",
                "updatedAt": "2022-04-06T20:50:56.000Z",
                "section_id": 4,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 4,
                    "name": "Секция 3",
                    "theme": "Как это работает: демонстрация решений",
                    "order": 4,
                    "createdAt": "2022-03-14T11:59:41.000Z",
                    "updatedAt": "2022-04-02T18:52:19.000Z"
                },
                "Speaker": []
            }, {
                "id": 46,
                "name": "<p><strong>ОФИСНОЕ ПО В УЧЕБНОМ ПРОЦЕССЕ: НЕЗАМЕТНЫЕ МЕЛОЧИ И БОЛЬШИЕ ПРОБЛЕМЫ!</strong></p>",
                "time": "15:40 - 16:00",
                "content": "",
                "order": 13,
                "createdAt": "2022-04-06T20:40:53.000Z",
                "updatedAt": "2022-04-20T12:43:24.000Z",
                "section_id": 5,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 5,
                    "name": "Секция 4",
                    "theme": "Система воспроизводства ИТ-кадров",
                    "order": 5,
                    "createdAt": "2022-03-14T12:00:01.000Z",
                    "updatedAt": "2022-04-14T12:59:18.000Z"
                },
                "Speaker": [{
                    "id": 36,
                    "name": "Борисов Андрей",
                    "job": "Ведущий инженер-исследователь ГК «Астра»",
                    "avatar": "/images/V_Астра_Борисов-Андрей.jpg",
                    "info": "<p>30 лет занимается разработкой и внедрением информационных систем специального назначения, из них более 20 лет на Linux-платформах ОС МСВС и ОС Astra Linux Special Edition.</p>",
                    "createdAt": "2022-04-03T09:47:09.000Z",
                    "updatedAt": "2022-04-04T21:02:55.000Z",
                    "program_speakers": {
                        "createdAt": "2022-04-19T21:39:07.000Z",
                        "updatedAt": "2022-04-19T21:39:07.000Z",
                        "program_id": 46,
                        "speaker_id": 36
                    }
                }]
            }, {
                "id": 27,
                "name": "<p><strong>НЕФОРМАЛЬНОЕ ОБЩЕНИЕ</strong></p>",
                "time": "16:00 - 18:00",
                "content": "<p> </p>",
                "order": 14,
                "createdAt": "2022-04-02T11:55:00.000Z",
                "updatedAt": "2022-04-04T12:27:58.000Z",
                "section_id": 2,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 2,
                    "name": "Секция 1",
                    "theme": "Практика импортозамещения",
                    "order": 2,
                    "createdAt": "2022-03-14T11:58:59.000Z",
                    "updatedAt": "2022-04-02T18:52:17.000Z"
                },
                "Speaker": []
            }, {
                "id": 28,
                "name": "<p><strong>ВОПРОСЫ И ОТВЕТЫ</strong></p>",
                "time": "16:00 - 16:10",
                "content": "<p> </p>",
                "order": 14,
                "createdAt": "2022-04-02T11:59:58.000Z",
                "updatedAt": "2022-04-18T21:03:06.000Z",
                "section_id": 3,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 3,
                    "name": "Секция 2",
                    "theme": "ИТ-решения для цифровизации",
                    "order": 3,
                    "createdAt": "2022-03-14T11:59:20.000Z",
                    "updatedAt": "2022-04-02T18:52:18.000Z"
                },
                "Speaker": []
            }, {
                "id": 19,
                "name": "<p><strong>НЕФОРМАЛЬНОЕ ОБЩЕНИЕ</strong></p>",
                "time": "16:00 - 18:00",
                "content": "<p> </p>",
                "order": 14,
                "createdAt": "2022-03-14T12:11:35.000Z",
                "updatedAt": "2022-04-04T12:27:47.000Z",
                "section_id": 4,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 4,
                    "name": "Секция 3",
                    "theme": "Как это работает: демонстрация решений",
                    "order": 4,
                    "createdAt": "2022-03-14T11:59:41.000Z",
                    "updatedAt": "2022-04-02T18:52:19.000Z"
                },
                "Speaker": []
            }, {
                "id": 16,
                "name": "<p><strong>НЕФОРМАЛЬНОЕ ОБЩЕНИЕ</strong></p>",
                "time": "16:10 - 18:00",
                "content": "<p> </p>",
                "order": 15,
                "createdAt": "2022-03-14T12:10:14.000Z",
                "updatedAt": "2022-04-18T21:04:27.000Z",
                "section_id": 3,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 3,
                    "name": "Секция 2",
                    "theme": "ИТ-решения для цифровизации",
                    "order": 3,
                    "createdAt": "2022-03-14T11:59:20.000Z",
                    "updatedAt": "2022-04-02T18:52:18.000Z"
                },
                "Speaker": []
            }, {
                "id": 65,
                "name": "<p><strong>ВОПРОСЫ И ОТВЕТЫ</strong></p>",
                "time": "16:00 - 16:20",
                "content": "",
                "order": 15,
                "createdAt": "2022-04-09T16:06:11.000Z",
                "updatedAt": "2022-04-19T21:40:59.000Z",
                "section_id": 5,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 5,
                    "name": "Секция 4",
                    "theme": "Система воспроизводства ИТ-кадров",
                    "order": 5,
                    "createdAt": "2022-03-14T12:00:01.000Z",
                    "updatedAt": "2022-04-14T12:59:18.000Z"
                },
                "Speaker": []
            }, {
                "id": 22,
                "name": "<p><strong>НЕФОРМАЛЬНОЕ ОБЩЕНИЕ</strong></p>",
                "time": "16:20 - 18:00 ",
                "content": "<p> </p>",
                "order": 16,
                "createdAt": "2022-03-14T12:12:44.000Z",
                "updatedAt": "2022-04-18T21:15:16.000Z",
                "section_id": 5,
                "day_id": 2,
                "programDay": {
                    "id": 2,
                    "name": "День 1",
                    "createdAt": "2022-03-14T11:55:01.000Z",
                    "updatedAt": "2022-03-14T11:55:01.000Z"
                },
                "programSection": {
                    "id": 5,
                    "name": "Секция 4",
                    "theme": "Система воспроизводства ИТ-кадров",
                    "order": 5,
                    "createdAt": "2022-03-14T12:00:01.000Z",
                    "updatedAt": "2022-04-14T12:59:18.000Z"
                },
                "Speaker": []
            }],
        deleteMessage: false,
    },
    getters: {
        getUser(state) {
            return state.user
        },
        getDeleteMessage(state) {
            return state.deleteMessage
        },
        getProgramNoFilter(state) {
            const program = _.forEach(state.program, item => {
                const date = item.time.split('-')
                item.timeStart = date[0].trim()
                item.timeEnd = date[1].trim()
            })
            return program
        },
        getProgram(state) {
            if (state.program) {
                let arr = []
                for (let i in state.program) {
                    const program = state.program[i]
                    const date = program.time.split('-')
                    program.timeStart = date[0].trim()
                    program.timeEnd = date[1].trim()
                }
                const programSort = _.sortBy(state.program, item => {
                    return moment(item.timeStart, 'HH-mm').tz('Europe/Moscow').format('YYYY-MM-DD HH:mm')
                })
                for (let program of programSort) {
                    let findDay = _.findIndex(arr, item => item.id === program.day_id)
                    if (findDay === -1) {
                        arr.push({
                            id: program.day_id,
                            name: program.programDay.name,
                            sections: []
                        })
                        findDay = arr.length - 1
                    }
                    let findSection = _.findIndex(arr[findDay].sections, item => {
                        return item.id === program.section_id
                    })
                    if (findSection === -1) {
                        arr[findDay].sections.push({
                            id: program.section_id,
                            name: program.programSection.name,
                            theme: program.programSection.theme,
                            order: program.programSection.order,
                            program: []
                        })
                        findSection = arr[findDay].sections.length - 1
                    }
                    const contentLength = program.content.replace(/(<[^>]+>|<[^>]>|<\/[^>]>)/g, '').length
                    arr[findDay].sections[findSection].program.push({
                        content: contentLength > 1 ? program.content : '',
                        order: program.order,
                        name: program.name,
                        time: program.time,
                        id: program.id,
                        speaker: program.Speaker,
                        dayId: program.day_id,
                        sectionId: program.section_id,
                        timeStart: program.timeStart,
                        timeEnd: program.timeEnd
                    })
                }
                for (let i in arr) {
                    arr[i].sections = _.sortBy(arr[i].sections, ['order'])
                }
                return arr
            }
            return []
        },
        isAuth(state) {
            return !!state.user
        },
        getRightModal(state) {
            return state.rightModal
        },
        getEvent(state) {
            return state.event || {}
        },
        getSettings(state) {
            return state.settings
        },
        getSettingByValue(state) {
            return (name) => {
                const find = _.find(state.settings, item => item.name === name)
                if (find) {
                    return find.value
                } else {
                    return undefined
                }
            }
        },
        getContent(state) {
            return state.content
        },
        getContentByType(state) {
            return (type) => {
                if (state.content) {
                    let items = []
                    for (let item of state.content) {
                        if (item.type === type) {
                            items.push(item)
                        }
                    }
                    items = _.orderBy(items, ['order'])
                    return items
                } else {
                    return null
                }
            }
        }
    },
    mutations: {
        changeRightModal(state, payload) {
            return state.rightModal = payload
        },
        changeDeleteMessage(state, payload) {
            return state.deleteMessage = payload
        },
        changeUser(state, payload) {
            return state.user = payload
        },
        changeEvent(state, payload) {
            return state.event = payload
        },
        changeSettings(state, payload) {
            return state.settings = payload
        },
        changeContent(state, payload) {
            return state.content = payload
        },
        changeProgram(state, payload) {
            return state.program = payload
        }
    },
    actions: {
        loadEvent({commit}) {
            axios.get('/event')
                .then(resp => {
                    commit('changeEvent', resp.data)
                })
                .catch(e => {
                    console.log(e)
                })
        },
        loadContent({commit}) {
            axios.get('/content')
                .then(resp => {
                    commit('changeContent', resp.data)
                })
                .catch(e => {
                    console.log(e)
                })
        },
        loadSettings({commit}) {
            axios.get('/setting/client')
                .then(resp => {
                    commit('changeSettings', resp.data)
                })
                .catch(e => {
                    console.log(e)
                })
        },
        loadProgram({commit}) {
            axios.get('/program')
                .then(resp => {
                    commit('changeProgram', resp.data)
                })
                .catch(e => {
                    console.log(e)
                })
        }
    },
    modules: {}
})
